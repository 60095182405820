import React from 'react';
import { Button } from 'react-bootstrap';
import i18next from 'i18next';
import app_dashboard from '../assets/images/app_dashboard.png';

const Main = ({ handleClick }) => {
    return <div className="hero-section app-hero">
        <div className="container">
            <div className="hero-content app-hero-content text-center">
                <div className="row justify-content-md-center">
                    <div className="col-md-10">
                        <h1 className="wow fadeInUp" data-wow-delay="0s">
                            Octolance
                        </h1>
                        <h2 className="wow fadeInUp" data-wow-delay="0s">
                            {i18next.t("Your all-in-one freelance management software")}

                        </h2>
                        <p className="wow fadeInUp" data-wow-delay="0.2s">
                            {i18next.t("Effortlessly manage your freelance activities, stay organized, and grow your business")}
                        </p>
                        <Button key={"freeSignup"}
                            onClick={handleClick}
                            variant="primary"
                        >
                            <text style={{ cursor: "pointer", fontSize: "18px", fontWeight: "bold" }}>
                                {i18next.t("30 days free trial")}
                            </text>
                        </Button>
                        <div><text class="text-muted" >
                            {i18next.t("No credit card required and no commitment")}
                        </text></div>
                    </div>
                    <div className="col-md-12">
                        <div className="hero-image">
                            <img className="img-fluid" src={app_dashboard} alt="App Dashboard" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Main;
