import { Get, Post, Put, Delete } from "../../utils/base-api-request";
import { store } from "../../store/index";
import { combineDateAndTime, getDay, getTime, newDate } from "../../utils/date";

const getEventsUrl = process.env.REACT_APP_BASE_API + "api/event/all";
const getEventUrl = process.env.REACT_APP_BASE_API + "api/event";
const removeEventUrl = process.env.REACT_APP_BASE_API + "api/event";
const addEventUrl = process.env.REACT_APP_BASE_API + "api/event";
const updateEventUrl = process.env.REACT_APP_BASE_API + "api/event";
const updateEventByDatesUrl =
  process.env.REACT_APP_BASE_API +
  "api/event/dates/{userId}/{startDate}/{endDate}";
const dismissEventsUrl = process.env.REACT_APP_BASE_API + "api/event/dismiss";

export const sortData = (array) => {
  return array.sort((a, b) => {
    if (a.start < b.start) return 1;
    if (a.start > b.start) return -1;

    return 0;
  });
};

export const getAll = async () => {
  const events = await Get(
    getEventsUrl + "/" + store.getState().authentication.user.id
  );

  return sortData(events.map((event) => eventDtoToEventView(event)));
};

export const getByDatesAll = async (startDate, endDate) => {
  const events = await Get(
    updateEventByDatesUrl
      .replace("{userId}", store.getState().authentication.user.id)
      .replace("{startDate}", startDate)
      .replace("{endDate}", endDate)
  );

  return sortData(events.map((event) => eventDtoToEventView(event)));
};

export const get = async (id) => {
  const response = await Get(getEventUrl + "/" + id);
  return eventDtoToEventView(response);
};

export const add = async (professionalInfo) => {
  if (professionalInfo["userId"] === undefined)
    professionalInfo["userId"] = store.getState().authentication.user.id;

  const response = await Post(
    addEventUrl,
    eventViewToEventDto(professionalInfo)
  );
  return eventDtoToEventView(response);
};

export const update = async (professionalInfo) => {
  if (professionalInfo["userId"] === undefined)
    professionalInfo["userId"] = store.getState().authentication.user.id;

  const response = await Put(
    updateEventUrl,
    eventViewToEventDto(professionalInfo)
  );
  return eventDtoToEventView(response);
};

export const dismissEvents = async (events) => {
  const response = await Put(
    dismissEventsUrl,
    events.map((event) => eventDismissViewToEventDismissDto(event))
  );
  return sortData(response.map((event) => eventDtoToEventView(event)));
};

export const remove = async (id) => {
  return await Delete(removeEventUrl + "/" + id);
};

const eventDtoToEventView = (item) => {
  const allDay =
    (item.end - item.start) % 86400000 >= 86399000 ||
    (item.end - item.start) % 86400000 === 0;
  return {
    ...item,
    desc: item.description,
    start: newDate(item.start),
    end: newDate(item.end),
    schedulerStartDate: item.schedulerStartDate
      ? newDate(item.schedulerStartDate)
      : item.schedulerStartDate,
    schedulerEndDate: item.schedulerEndDate
      ? newDate(item.schedulerEndDate)
      : item.schedulerEndDate,
    allDay: allDay,
    days: item.days && item.days.length > 0 ? item.days.split(";") : [],
    months: item.months && item.months.length > 0 ? item.months.split(";") : [],
    dismissed: item.dismissed && item.dismissed.length > 0 ? item.dismissed.map(d => eventDismissDtoToEventDismissView(d)) : []
  };
};

const eventViewToEventDto = (item) => {
  return {
    ...item,
    description: item.desc,
    start: getTime(
      item.schedulerStartDate
        ? combineDateAndTime(item.schedulerStartDate, item.start)
        : item.allDay
          ? newDate(item.start).setHours(0, 0, 0, 0)
          : item.start
    ),
    end: getTime(
      item.schedulerStartDate
        ? combineDateAndTime(item.schedulerStartDate, item.end)
        : item.allDay
          ? newDate(
            newDate(item.end).setDate(getDay(item.end) + 1)
          ).setHours(0, 0, 0, 0)
          : item.end
    ),
    schedulerStartDate: item.schedulerStartDate
      ? getTime(item.schedulerStartDate)
      : item.schedulerStartDate,
    schedulerEndDate: item.schedulerEndDate
      ? getTime(item.schedulerEndDate)
      : item.schedulerEndDate,
    days: item.days && item.days.length > 0 ? item.days.join(";") : "",
    months: item.months && item.months.length > 0 ? item.months.join(";") : "",
    recurring: item.recurring ? parseInt(item.recurring) : 0,
  };
};

const eventDismissViewToEventDismissDto = (item) => {
  return {
    id: item.id,
    start: getTime(item.start),
    end: getTime(item.end),
  };
};

const eventDismissDtoToEventDismissView = (item) => {
  return {
    id: item.id,
    start: newDate(item.start),
    end: newDate(item.end),
  };
};
