import React from "react";
import i18next from "i18next";
import { FormGroup, Label } from "reactstrap";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { getMinutes, getTime, newDate } from "../../../../utils/date";
import { enGB, fr } from 'date-fns/locale';
import { fr as localFr, en as localEn } from '../../../../utils/constants';
import { useSelector } from "react-redux";

const locales = { [localFr]: fr, [localEn]: enGB };
const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-input": {
        paddingTop: "0.6rem",
        paddingRight: "0.89rem",
        paddingBottom: "0.6rem",
        paddingLeft: "0.89rem",
        fontSize: "15px",
        color: "#66615b",
        lineHeight: "normal",
        backgroundColor: "#FFFFFF",
      },
    },
  },
});

export const isValidDateTime = (value, minDate) => {
  return (
    !minDate ||
    getTime(value) >=
    getTime(newDate(minDate).setMinutes(getMinutes(minDate) + 1))
  );
};

const DateTime = (props) => {
  const {
    field,
    title,
    isRequired,
    isReadOnly,
    value,
    defaultValue,
    handleChange,
    minDate,
    isForm = true,
  } = props;
  const classes = useStyles();
  const [error, setError] = React.useState(null);
  const preferredLanguage = useSelector((state) => state.authentication.params.preferredLanguage);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case "maxDate":
      case "minDate": {
        return i18next.t("please choose a date greater than the start date");
      }
      case "maxTime":
      case "minTime": {
        return i18next.t("please choose a time greater than the start time");
      }
      case "invalidDate": {
        return i18next.t("your date is not valid");
      }

      default: {
        return "";
      }
    }
  }, [error]);

  if (isForm)
    return (
      <FormGroup key={"form" + field} controlid={"date-picker-inline" + field}>
        {title && <Label>{isRequired ? title + "*" : title}</Label>}
        <LocalizationProvider adapterLocale={locales[preferredLanguage]} dateAdapter={AdapterDateFns}>
          <DateTimePicker
            className={classes.root}
            value={value ? newDate(value) : defaultValue ? defaultValue : null}
            onChange={(newValue) => handleChange(field, newValue)}
            // format="dd/MM/yyyy HH:mm"
            disabled={isReadOnly}
            minDateTime={
              minDate
                ? newDate(minDate).setMinutes(
                  getMinutes(minDate) + 1
                )
                : 0
            }
            slotProps={{
              field: { sx: { width: "100%" } },
              textField: {
                error: (isRequired && !value) || !!errorMessage,
                helperText: (isRequired && !value) ? i18next.t("required field") : errorMessage,
              },
            }}
            onError={(newError) => setError(newError)}
          // ampm={false}
          />
        </LocalizationProvider>
      </FormGroup>
    );
  else
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          className={classes.root}
          value={value ? newDate(value) : defaultValue ? defaultValue : null}
          onChange={(newValue) => handleChange(field, newValue)}
          format="dd/MM/yyyy HH:mm"
          disabled={isReadOnly}
          minDateTime={
            minDate
              ? newDate(minDate).setMinutes(getMinutes(minDate) + 1)
              : 0
          }
          slotProps={{
            field: { sx: { width: "105%" } },
            textField: {
              error: (isRequired && !value) || !!errorMessage,
              helperText: (isRequired && !value) ? i18next.t("required field") : errorMessage,
            },
          }}
          onError={(newError) => setError(newError)}
        />
      </LocalizationProvider>
    );
};

export default DateTime;
