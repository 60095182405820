import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Form, InputGroup } from "react-bootstrap";
import NavLink from "./Navlink";
import { choiceInput } from "./Choice";
import { textInput } from "./Text";
import { DeleteWhitoutFab } from "../../Icons/icons";
import { formType } from "../forms";
import { v4 } from 'uuid';
import i18next from "i18next";
import { numericInput } from './Numeric';
import CustomDate from './Date';

const Filter = forwardRef((props, ref) => {
  const {
    field,
    title,
    isRequired,
    filters,
    handleChange,
    addnewTitle,
    value,
    enableAdd,
    limitedTo,
    initialFilters,
  } = props;

  const [filterfields, setFilterFields] = useState(initialFilters || {});

  useEffect(() => {
    if (value && value.length > 0) {
      const existingFilters = { ...filterfields };
      value.forEach((v) => (existingFilters[v4()] = v));
      setFilterFields(existingFilters);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newFilters = [];
    Object.values(filterfields).forEach((element) => {
      if (element.field) newFilters.push(element);
    });

    handleChange(field, newFilters);
    // eslint-disable-next-line
  }, [filterfields]);

  const onChangeField = (field, value) => {
    const editedfilterfields = { ...filterfields };
    const newField = { field: value };

    filters[value].fields.forEach((field) => {
      if (field.defaultValue) {
        newField[field.field] = field.defaultValue;
      }
    });

    editedfilterfields[field] = newField;
    setFilterFields(editedfilterfields);
  };

  const onChangeValue = (field, subfield, value) => {
    const editedfilterfields = { ...filterfields };
    editedfilterfields[field?.split('_')[0]][subfield] = value;
    setFilterFields(editedfilterfields);
  };

  useImperativeHandle(ref, () => ({
    onDelete: (key) => { const elementToDelete = Object.keys(filterfields).find(x => filterfields[x].field === key); onDelete(elementToDelete) }
  }));

  const onDelete = (key) => {
    const editedfilterfields = { ...filterfields };
    delete editedfilterfields[key.split('_')[0]];
    setFilterFields(editedfilterfields);
  };

  const filterComponents = (key) => {
    if (!filterfields[key].field) return <React.Fragment />;
    return filters[filterfields[key].field].fields.map((item) => {
      const borderRight = { borderRight: 'solid 2px #ccc' };
      switch (item?.type) {
        case formType.text:
          return textInput(
            key + '_text',
            true,
            false,
            '',
            filterfields[key][item.field],
            (field, value) => onChangeValue(field, item.field, value),
            { ...borderRight, ...(item.style ?? {}) },
            undefined,
            undefined,
            false,
            undefined,
            false
          );
        case formType.choice:
          return choiceInput(
            key + '_choice',
            true,
            false,
            `${i18next.t("Select")} ...`,
            filterfields[key][item.field],
            undefined,
            item.options,
            (field, value) => onChangeValue(field, item.field, value),
            null,
            { ...borderRight, ...(item.style ?? {}) },
            false,
            undefined,
            false
          );
        case formType.numeric:
          return numericInput(
            key + '_numeric',
            true,
            false,
            '',
            filterfields[key][item.field],
            (field, value) => onChangeValue(field, item.field, value),
            false,
            undefined
          );
        case formType.date:
          return (
            <CustomDate
              field={key + '_date'}
              isRequired={false}
              isReadOnly={false}
              value={filterfields[key][item.field]}
              defaultValue={null}
              handleChange={(field, value) =>
                onChangeValue(field, item.field, value)
              }
              isForm={false}
            />
          );
        default:
          return <React.Fragment />;
      }
    });
  };

  return (
    <Form.Group key={'form' + field} controlid={field}>
      {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}
      {Object.keys(filterfields).map((key) => {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <InputGroup>
              <div
                style={{
                  width: '100%',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {choiceInput(
                  key,
                  true,
                  false,
                  `${i18next.t("Select")} ...`,
                  filterfields[key].field,
                  undefined,
                  Object.keys(filters).reduce(
                    (a, v) => ({ ...a, [v]: filters[v].display }),
                    {},
                  ),
                  onChangeField,
                  undefined,
                  {},// {backgroundColor: 'rgb(0 0 0 / 10%)' },
                  false,
                  () => { }
                )}
                {filterComponents(key)}
              </div>
            </InputGroup>
            {enableAdd && (
              <div
                style={{
                  paddingLeft: '10px',
                }}
              >
                <DeleteWhitoutFab
                  onClick={() => onDelete(key + '_delete')}
                />
              </div>
            )}
          </div>
        );
      })}
      {(enableAdd && (!limitedTo || limitedTo > Object.keys(filterfields).length)) && (
        <div style={{
          paddingTop: '5px',
        }}>
          <NavLink
            title={addnewTitle ?? i18next.t('Add filter')}
            action={() =>
              setFilterFields({ ...filterfields, ...{ [v4()]: { field: '' } } })
            }
          />
        </div>
      )}
    </Form.Group>
  );
});

export default Filter;