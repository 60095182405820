import React from "react";
import Choice from "./inputs/Choice";
import Text from "./inputs/Text";
import Label from "./inputs/Label";
import ChoiceAddons from "./inputs/ChoiceAddons";
import Numeric from "./inputs/Numeric";
import Description from "./inputs/Description";
import Email from "./inputs/Email";
import Password from "./inputs/Password";
import Switch from "./inputs/Switch";
import Checkbox from "./inputs/Checkbox";
import Datetime from "./inputs/Datetime";
import Date from "./inputs/Date";
import Time from "./inputs/Time";
import Component from "./others/Component";
import Image from "./others/Image";
import Cancel from "./buttons/Cancel";
import Delete from "./buttons/Delete";
import Submit from "./buttons/Submit";
import Upload from "./inputs/Upload";
import Save from "./buttons/Save";
import Typeahead from "./inputs/Typeahead";
import InputGroup from "./inputs/inputGroup";
import ListGroup from "./others/ListGroup";
import Filter from "./inputs/filter";
import Phone from "./inputs/Phone";
import MultiChoice from "./inputs/multi-choice";
import MultiSelect from "./inputs/multi-select";
import Recurring from "./inputs/Recurring";
import MultiSelectText from "./inputs/multi-selectText";
import Iban from "./inputs/Iban";
import ColorSelector from "./inputs/ColorSelector";

export const formType = {
  text: "text",
  inputGroup: "inputGroup",
  listGroup: "listGroup",
  filter: "filter",
  typeahead: "typeahead",
  label: "label",
  choice: "choice",
  multiChoice: "multiChoice",
  multiSelect: "multiSelect",
  multiSelectText: "multiSelectText",
  choiceAddons: "choiceAddons",
  component: "component",
  numeric: "numeric",
  description: "description",
  email: "email",
  phone: "phone",
  date: "date",
  time: "time",
  datetime: "datetime",
  checkbox: "checkbox",
  switch: "switch",
  password: "password",
  upload: "upload",
  submit: "submit",
  cancel: "cancel",
  image: "image",
  save: "save",
  recurring: "recurring",
  boolean: "boolean",
  iban: "iban",
  colorSelector: "colorSelector"
};

export function RenderForm(props) {
  switch (props.form.formType) {
    case formType.text:
      return (
        <Text
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          style={props.form.style}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          label={props.form.label}
          enableCopy={props.form.enableCopy}
          info={props.form.info}
        />
      );
    case formType.iban:
      return (
        <Iban
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          label={props.form.label}
          enableCopy={props.form.enableCopy}
          info={props.form.info}
        />
      );
    case formType.phone:
      return (
        <Phone
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.inputGroup:
      return (
        <InputGroup
          field={props.form.field}
          title={props.form.title}
          isRequired={props.form.isRequired}
          value={props.value}
          inputs={props.form.inputs}
          addnewTitle={props.form.addnewTitle}
          handleChange={props.handleChange}
          info={props.form.info}
        />
      );
    case formType.listGroup:
      return (
        <ListGroup
          field={props.form.field}
          title={props.form.title}
          isRequired={props.form.isRequired}
          value={props.value}
          handleChange={props.handleChange}
          style={props.form.style}
        />
      );
    case formType.filter:
      return (
        <Filter
          ref={props.form.ref}
          field={props.form.field}
          title={props.form.title}
          isRequired={props.form.isRequired}
          filters={props.form.filters}
          handleChange={props.handleChange}
          addnewTitle={props.form.addnewTitle}
          value={props.value}
          enableAdd={props.form.enableAdd}
          limitedTo={props.form.limitedTo}
          initialFilters={props.form.initialFilters}
        />
      );
    case formType.typeahead:
      return (
        <Typeahead
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          options={props.form.options}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.label:
      return <Label field={props.form.field} title={props.form.title} info={props.form.info} />;
    case formType.choice:
      return (
        <Choice
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          lookup={props.form.lookup}
          defaultValue={props.form.defaultValue}
          sortBy={props.form.sortBy}
          style={props.form.style}
          dirty={props.form.dirty}
          enableCopy={props.form.enableCopy}
          info={props.form.info}
        />
      );
    case formType.multiChoice:
      return (
        <MultiChoice
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          handleChange={props.handleChange}
          options={props.form.lookup}
          dirty={props.form.dirty}
          info={props.form.info}
          placeholder={props.form.placeholder}
        />
      );
    case formType.multiSelect:
      return (
        <MultiSelect
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          handleChange={props.handleChange}
          options={props.form.lookup}
          dirty={props.form.dirty}
          info={props.form.info}
          placeholder={props.form.placeholder}
        />
      );
    case formType.multiSelectText:
      return (
        <MultiSelectText
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          handleChange={props.handleChange}
          options={props.form.lookup}
          dirty={props.form.dirty}
          placeholder={props.form.placeholder}
          enableCopy={props.form.enableCopy}
          textValidatorFunc={props.form.textValidatorFunc}
          validationErrorMessage={props.form.validationErrorMessage}
          info={props.form.info}
        />
      );
    case formType.choiceAddons:
      return (
        <ChoiceAddons
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          lookup={props.form.lookup}
          addOnsComponent={props.form.addOnsComponent}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.numeric:
      return (
        <Numeric
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          label={props.form.label}
          info={props.form.info}
        />
      );
    case formType.description:
      return (
        <Description
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          rows={props.form.rows}
          maxLength={props.form.maxLength}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.email:
      return (
        <Email
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.datetime:
      return (
        <Datetime
          field={props.form.field}
          title={props.form.title}
          isRequired={props.form.isRequired}
          value={props.value}
          handleChange={props.handleChange}
          minDate={props.form.minDate}
          info={props.form.info}
          isForm={props.form.isForm}
          defaultValue={props.form.defaultValue}
        />
      );
    case formType.date:
      return (
        <Date
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          defaultValue={props.form.defaultValue}
          handleChange={props.handleChange}
          minDate={props.form.minDate}
          info={props.form.info}
          isForm={props.form.isForm}
        />
      );
    case formType.time:
      return (
        <Time
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          defaultValue={props.form.defaultValue}
          handleChange={props.handleChange}
          minDate={props.form.minDate}
          info={props.form.info}
        />
      );
    case formType.checkbox:
      return (
        <Checkbox
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          handleChange={props.handleChange}
          info={props.form.info}
        />
      );
    case formType.switch:
      return (
        <Switch
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          isInline={props.form.isInline}
          value={props.value}
          handleChange={props.handleChange}
          info={props.form.info}
        />
      );
    case formType.colorSelector:
      return (
        <ColorSelector
          field={props.form.field}
          title={props.form.title}
          isRequired={props.form.isRequired}
          isReadOnly={props.form.isReadOnly}
          defaultValue={props.form.defaultValue}
          value={props.value}
          handleChange={props.handleChange}
          style={props.style}
        />
      );
    case formType.password:
      return (
        <Password
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.upload:
      return (
        <Upload
          field={props.form.field}
          title={props.form.title}
          isRequired={props.form.isRequired}
          value={props.value}
          placeholder={props.form.placeholder}
          handleChange={props.handleChange}
          dirty={props.form.dirty}
          info={props.form.info}
        />
      );
    case formType.submit:
      return <Submit field={props.form.field} title={props.form.title} />;
    case formType.delete:
      return (
        <Delete
          field={props.form.field}
          isReadOnly={props.form.isReadOnly}
          title={props.form.title}
          handleChange={props.handleChange}
        />
      );
    case formType.cancel:
      return (
        <Cancel
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          handleCancel={props.handleCancel}
        />
      );
    case formType.save:
      return (
        <Save
          field={props.form.field}
          title={props.form.title}
          isReadOnly={props.form.isReadOnly}
          handleSave={props.handleSave}
        />
      );
    case formType.image:
      return <Image field={props.form.field} value={props.value} />;
    case formType.component:
      return (
        <Component field={props.form.field} content={props.form.content} title={props.form.title}
          isRequired={props.form.isRequired} style={props.form.style} info={props.form.info} />
      );
    case formType.recurring:
      return (
        <Recurring field={props.form.field} title={props.form.title} handleClick={props.handleChange} asForm={props.form.asForm} />
      );
    default:
      return <React.Fragment />;
  }
}
