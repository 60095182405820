import React from "react";
import { Button } from "react-bootstrap";
import { subProductInterval } from "../../../utils/constants";
import { useTranslation } from "react-i18next";
import { CheckCircleOutline } from "../Icons/icons";
import { Table } from "react-bootstrap";
import './PriceCard.css'
import { sortByPropertyAsc } from "../../../utils/converter";

const PriceCard = (props) => {
    const { t } = useTranslation();
    const { products, selected, handleSelect, isReadonly } = props;
    const features = [
        {
            title: t("Dashboard"),
            features: [
                t("Track your activities with a detailed overview"),
                t("Financial Snapshot"),
                t("Analyze your performance for continuous improvement"),
                t("Revenue & Expenses Overview"),
                t("Work Time & Task Distribution"),
                t("Upcoming Events & Reminders"),
                t("Recent Prospections"),
            ],
            description: t("Get a complete view of your activities and progress"),
        },
        {
            title: t("Task Board"),
            features: [
                t("Visualize your tasks with Kanban boards"),
                t("Add flexibility with customizable Columns, ticket Tagging & Prioritization"),
                t("Track time and manage task estimates effortlessly"),
                t("Task Statistics")
            ],
            description: t("Organize, prioritize, and track tasks effectively"),
        },
        {
            title: t("Calendar"),
            features: [
                t("Stay organized with an intuitive calendar view"),
                t("Create and manage single or recurring events"),
                t("Set reminders to never miss important dates"),
            ],
            description: t("Plan your schedule and stay on top of your commitments"),
        },
        {
            title: t("Time Sheets"),
            features: [
                t("Project/Task Tracking"),
                t("Overview of worked hours on a daily or monthly basis"),
                t("Manage time off, sick leave, or vacation"),
                t("Billable vs Non-Billable days"),
                t("Reporting and Analytics")
            ],
            description: t("Monitor time spent and boost efficiency"),
        },
        {
            title: t("Billing"),
            features: [
                t("Create unlimited invoices effortlessly"),
                t("Stay compliant with e-invoicing regulations"),
                t("Personalize invoices to match your brand"),
                t("Generate and send invoices in just a few clicks"),
                t("Automate reminders for overdue payments"),
                t("Electronic signatures for documents"),
                t("Track client contracts seamlessly"),
                t("Customize client-specific emails easily"),
                t("Add unlimited client contacts"),
                t("Synchronize appointments with built-in reminders"),
            ],
            description: t("Simplify your invoicing and payment processes"),
        },
        {
            title: t("Treasury"),
            features: [
                t("Manually manage transactions with ease"),
                t("Track revenue based on payments received"),
                t("Monitor collected and deductible VAT"),
                t("Automate expense entries for accuracy"),
                t("Categorize expenses to manage budgets effectively"),
                t("Schedule and automate recurring payments"),
            ],
            description: t("Optimize financial management and cash flow"),
        },
        {
            title: t("CRM"),
            features: [
                t("Maintain and generate your CV in PDF easily"),
                t("Manage company profiles and client data effectively"),
                t("Maintain a unified database for all your contacts"),
                t("Track and manage offers received from clients effortlessly"),
            ],
            description: t("Boost client relationships and recruitment processes"),
        },
    ];

    return <Table className="pricing-features" hover borderless responsive>
        <thead>
            <tr>
                <th></th>
                {sortByPropertyAsc(products, "amount", true).map(item => (
                    <th>
                        <div className="pricing-card">
                            <div className="header">
                                <h2>{t(item.title)}</h2>
                                <div style={{ width: "210px", color: "cadetblue", display: "contents" }}>{item.description ? `${t("Savings of")} ${item.description} ${t("including tax / year")}` : <br />} </div>
                            </div>
                            <div className="plan-price">
                                {`${item.amount} ${item.currency}`}
                            </div>
                            <div style={{ marginTop: '-5px' }}>
                                <p className="plan-frequency">
                                    {`${t("HT")} / ${item.intervalCount > 1 ? item.intervalCount + " " : ""} ${t(subProductInterval()[item.interval])}`}</p>
                            </div>
                            <div style={{ margin: "15px 10px 15px 10px" }}>
                                <p className="plan-ttc" >{`${t("That is")} ${Number.parseFloat(parseFloat(item.amount) * 1.2).toFixed(2)}€ ${t("TTC")} / ${item.intervalCount > 1 ? item.intervalCount + " " : ""} ${t(subProductInterval()[item.interval])}`}</p>
                            </div>
                            <div style={{ margin: "30px 10px 30px 10px" }}>
                                {!(selected?.id === item.id) && !isReadonly && (
                                    <Button variant="primary" onClick={() => handleSelect(item)} block>
                                        {t(item.choosePlanText)}
                                    </Button>
                                )}
                                {(selected?.id === item.id) && !isReadonly && (
                                    <Button variant="outline-secondary" block disabled>
                                        {t("Selected plan")}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {features.map(feat =>
                <>
                    <tr>
                        <td className="feature-header">{feat.title}</td>
                        {products.map(() => (
                            <>
                                <td className="feature-header"></td>
                                <td className="feature-header"></td>
                            </>
                        ))}
                    </tr>
                    {feat.features.map(fe =>
                        <tr>
                            <td className="feature-col">{fe}</td>
                            {products.map(() => (
                                <td className="feature-col"><CheckCircleOutline className="feature-check-icon" /></td>))}
                        </tr>
                    )}
                </>
            )}
        </tbody>
    </Table>
};

export default PriceCard;
