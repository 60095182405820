export const ALL = "perm_admin";
export const MANAGE = "manage";

/* ------------------------------dashboard------------------------------- */
export const DASHBOARD_MENU = "perm_dashboard_menu";
export const STATS_BILLS_MENU = "perm_stats_bills_menu";

/* ------------------------------personalInfo------------------------------- */
export const PERSONAL_INFO_MENU = "perm_personalInfo_menu";
export const PERSONAL_INFO_UPDATE = "perm_personalInfo_update";

/* ------------------------------personalInfo------------------------------- */
export const SUBSCRIPTION_MENU = "perm_subscription_menu";
export const SUBSCRIPTION_UPDATE = "perm_subscription_update";

/* ----------------------------professionalInfo----------------------------- */
export const PROFESSIONAL_INFO_MENU = "perm_professionalInfo_menu";
export const PROFESSIONAL_INFO_UPDATE = "perm_professionalInfo_update";

/* ------------------------------------CV----------------------------------- */
export const CV_MENU = "perm_cv_menu";
export const CV_PRINT = "perm_cv_print";

/* ----------------------------------company------------------------------- */
export const COMPANY_MENU = "perm_company_menu";
export const COMPANY_ADD = "perm_company_add";
export const COMPANY_DELETE = "perm_company_delete";
export const COMPANY_UPDATE = "perm_company_update";

/* ----------------------------------contact------------------------------- */
export const CONTACT_MENU = "perm_contact_menu";
export const CONTACT_ADD = "perm_contact_add";
export const CONTACT_DELETE = "perm_contact_delete";
export const CONTACT_UPDATE = "perm_contact_update";

/* ----------------------------------offer------------------------------- */
export const OFFER_MENU = "perm_offer_menu";
export const OFFER_ADD = "perm_offer_add";
export const OFFER_DELETE = "perm_offer_delete";
export const OFFER_UPDATE = "perm_offer_update";

/* ----------------------------------contracts------------------------------- */
export const CONTRACT_MENU = "perm_contract_menu";
export const CONTRACT_ADD = "perm_contract_add";
export const CONTRACT_DELETE = "perm_contract_delete";
export const CONTRACT_UPDATE = "perm_contract_update";

/* ----------------------------------timesheet------------------------------- */
export const TIMESHEET_MENU = "perm_timesheet_menu";
export const TIMESHEET_ADD = "perm_timesheet_add";
export const TIMESHEET_DELETE = "perm_timesheet_delete";
export const TIMESHEET_UPDATE = "perm_timesheet_update";

/* ----------------------------------billing------------------------------- */
export const BILLING_MENU = "perm_billing_menu";
export const BILLING_ADD = "perm_billing_add";
export const BILLING_DELETE = "perm_billing_delete";
export const BILLING_UPDATE = "perm_billing_update";

/* ----------------------------------optionSet------------------------------- */
export const OPTIONSET_MENU = "perm_optionSet_menu";
export const OPTIONSET_ADD = "perm_optionSet_add";
export const OPTIONSET_DELETE = "perm_optionSet_delete";
export const OPTIONSET_UPDATE = "perm_optionSet_update";

/* ----------------------------------Transaction------------------------------- */
export const TRANSACTION_MENU = "perm_transaction_menu";
export const TRANSACTION_ADD = "perm_transaction_add";
export const TRANSACTION_DELETE = "perm_transaction_delete";
export const TRANSACTION_UPDATE = "perm_transaction_update";

/* ----------------------------------Charge------------------------------- */
export const CHARGE_MENU = "perm_charge_menu";
export const CHARGE_ADD = "perm_charge_add";
export const CHARGE_DELETE = "perm_charge_delete";
export const CHARGE_UPDATE = "perm_charge_update";

/* ----------------------------------Access Management------------------------------- */
export const ROLE_MENU = "perm_accessRole_menu";
export const ROLE_MANAGE = "perm_accessRole_manage";

export const USER_MENU = "perm_accessUser_menu";
export const USER_MANAGE = "perm_accessUser_manage";

export const PRICEPLAN_ROLES_MENU = "perm_access_pricePlan_role_menu";
export const PRICEPLAN_ROLES_MANAGE = "perm_access_pricePlan_role_manage";

export const SUPPORT_REQUEST_MENU = "perm_access_support_request_menu";
export const SUPPORT_REQUEST_MANAGE = "perm_access_support_request_manage";


/* ----------------------------------Events------------------------------- */
export const EVENTS_MENU = "perm_events_menu";
export const EVENTS_ADD = "perm_events_add";
export const EVENTS_DELETE = "perm_events_delete";
export const EVENTS_UPDATE = "perm_events_update";

/* ----------------------------------ECOMMERCE------------------------------- */
export const ECOMMERCE_MENU = "perm_ecommerce_menu";

/* ----------------------------------TASK BOARD------------------------------- */
export const TASK_BOARD_MENU = "perm_task-board_menu";

export const permissions = {
  Admin: [ALL],
  Dashboard: [DASHBOARD_MENU, STATS_BILLS_MENU],
  "Personal info": [PERSONAL_INFO_MENU, PERSONAL_INFO_UPDATE],
  "Professional info": [PROFESSIONAL_INFO_MENU, PROFESSIONAL_INFO_UPDATE],
  Subscription: [SUBSCRIPTION_MENU, SUBSCRIPTION_UPDATE],
  CV: [CV_MENU, CV_PRINT],
  Company: [COMPANY_MENU, COMPANY_ADD, COMPANY_DELETE, COMPANY_UPDATE],
  Contact: [CONTACT_MENU, CONTACT_ADD, CONTACT_DELETE, CONTACT_UPDATE],
  Offer: [OFFER_MENU, OFFER_ADD, OFFER_DELETE, OFFER_UPDATE],
  Contracts: [CONTRACT_MENU, CONTRACT_ADD, CONTRACT_DELETE, CONTRACT_UPDATE],
  Timesheet: [
    TIMESHEET_MENU,
    TIMESHEET_ADD,
    TIMESHEET_DELETE,
    TIMESHEET_UPDATE,
  ],
  Task_Board: [
    TASK_BOARD_MENU,
  ],
  Billing: [BILLING_MENU, BILLING_ADD, BILLING_DELETE, BILLING_UPDATE],
  OptionSet: [
    OPTIONSET_MENU,
    OPTIONSET_ADD,
    OPTIONSET_DELETE,
    OPTIONSET_UPDATE,
  ],
  Transaction: [
    TRANSACTION_MENU,
    TRANSACTION_ADD,
    TRANSACTION_DELETE,
    TRANSACTION_UPDATE,
  ],
  Charge: [CHARGE_MENU, CHARGE_ADD, CHARGE_DELETE, CHARGE_UPDATE],
  "Access Management": [ROLE_MENU, ROLE_MANAGE, USER_MENU, USER_MANAGE, PRICEPLAN_ROLES_MENU, PRICEPLAN_ROLES_MANAGE],
  EVENTS: [EVENTS_MENU, EVENTS_ADD, EVENTS_DELETE, EVENTS_UPDATE],
};
