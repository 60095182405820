import React, { useState } from "react";
import { Form } from "react-bootstrap";
import i18next from "i18next";

const Password = (props) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      {props.title && (
        <Form.Label>
          {" "}
          {props.isRequired ? props.title + "*" : props.title}
        </Form.Label>
      )}
      <Form.Control
        style={{ fontSize: "var(--theme-font-size)" }}
        key={props.field}
        type="password"
        required={props.isRequired}
        value={props.value ? props.value : ""}
        onChange={(e) => {
          setIsDirty(true);
          props.handleChange(props.field, e.target.value);
        }}
        disabled={props.isReadOnly}
        placeholder={props.placeholder}
        isInvalid={(props.dirty ?? isDirty) && props.isRequired && !props.value}
      />
      <Form.Control.Feedback type="invalid">
        {i18next.t("required field")}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Password;
