import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import { defaultCvImage } from "../../../../utils/fileloader";
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import { CameraIcon } from "../../Icons/icons";
import { Dialog, dialogType } from "../../Dialogs/Dialog";
import Cropper from "../../Cropper/Cropper";
import i18next from "i18next";
import { setNotification } from "../../../../store/actions/common";
import { dispatch } from "../../../../store";

const ImageCustom = (props) => {
  const { handleUpload, content, rounded = true } = props;
  const fileInputRef = React.createRef();
  const [hover, toggleHover] = useState(false);
  const [show, setShow] = useState(false);
  const toggleDialog = () => setShow(!show);
  const [image, setImage] = useState();
  const [key, setKey] = useState(0);

  const onPictureChange = (e) => {
    if (!e.target.files[0]) {
      return { pictureName: null, pictureContent: defaultCvImage };
    } else {
      const file = e.target.files[0];
      if (!file.type.startsWith("image/")) {
        dispatch(setNotification({
          isSuccess: false,
          message: "Error : " + i18next.t("Please upload a valid image file."),
        }))

        return;
      }
      if (file.size > 5 * 1024 * 1024) { // 5 MB limit
        dispatch(setNotification({
          isSuccess: false,
          message: "Error : " + i18next.t("File size should not exceed 5MB."),
        }))
        return;
      }
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        toggleDialog();
      };
      reader.readAsDataURL(files[0]);
    }
  };

  const onSavePicture = (base64) => {
    handleUpload({ name: "file.name", content: base64 })
    setKey(key + 1);
    toggleDialog();
  };
  return (
    <>
      <div
        className="hover"
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        <LoadingOverlay
          styles={{
            overlay: (base) => ({
              ...base,
              "border-radius": rounded ? "50%" : "",
              width: "124px"
            }),
          }}
          active={hover}
          spinner={
            <React.Fragment>
              <CameraIcon onClick={() => fileInputRef.current.click()} />
            </React.Fragment>
          }
        >
          <Image
            src={content ?? defaultCvImage}
            roundedCircle={rounded}
            style={{
              width: "124px",
              height: "124px",
              border: "solid",
              borderColor: "white",
            }}
          />
        </LoadingOverlay>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        key={key}
        onChange={onPictureChange}
        hidden
      />
      {show && (
        <Dialog
          type={dialogType.large}
          handleClose={toggleDialog}
          component={
            <Cropper
              image={image}
              handleSave={onSavePicture}
              handleCancel={toggleDialog}
              rounded={rounded}
            />
          }
          headerless={true}
        />
      )}
    </>
  );
};

export default ImageCustom;
