import React from 'react';
import i18next from 'i18next';
import { Carousel } from 'react-bootstrap';
import app_bill from '../assets/images/app_bill.png';
import app_board from '../assets/images/app_board.png';
import app_calendar from '../assets/images/app_calendar.png';
import app_company from '../assets/images/app_company.png';
import app_contract from '../assets/images/app_contract.png';
import app_cra from '../assets/images/app_cra.png';
import app_cv from '../assets/images/app_cv.png';
import app_cv_detail from '../assets/images/app_cv_detail.png';
import app_offer from '../assets/images/app_offer.png';
import app_statistics from '../assets/images/app_statistics.png';

const Features = () => {
    return <div className="flex-features" id="features">
        <div className="container">
            <div className="flex-split">
                <div className="f-left wow fadeInUp" data-wow-delay="0s">
                    <div className="left-content">
                        <Carousel className="owl-carousel owl-theme" interval={2200}>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_statistics} alt="statistics" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_bill} alt="bill" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_contract} alt="contract" />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="f-right wow fadeInUp" data-wow-delay="0.2s">
                    <div className="right-content">
                        <h2>{i18next.t("Centralized management of contracts, invoices, and financial transactions")}</h2>
                        <p>{i18next.t("Centralize your contracts, invoices, and financial transactions Track your cash flow in real-time and manage everything simply")}</p>
                        <ul>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Centralize and manage all your client contracts")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Invoice creation, tracking, and management")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Monitor your cash flow in real-time")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Manage all your financial transactions in a centralized and simplified manner")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="flex-split">
                <div className="f-right">
                    <div className="right-content wow fadeInUp" data-wow-delay="0.2s">
                        <h2>{i18next.t("Prospecting new business opportunities and potential clients")}</h2>
                        <p>{i18next.t("Centralize your prospecting data resumes, contacts, companies, and offers Track and manage your business opportunities in an organized way to maximize your success")}</p>
                        <ul>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Create and centralize resumes directly from the interface")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Organize and track your interactions with prospects and partners")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Keep a directory of companies and related opportunities")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Track and manage your ongoing business proposals and offers")}
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="f-left">
                    <div className="left-content wow fadeInUp" data-wow-delay="0.3s">
                        <Carousel className="owl-carousel owl-theme" interval={2400}>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_offer} alt="offer" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_cv} alt="cv" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_cv_detail} alt="cv 2" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_company} alt="company" />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="flex-split">
                <div className="f-left wow fadeInUp" data-wow-delay="0s">
                    <div className="left-content">
                        <Carousel className="owl-carousel owl-theme" interval={2600}>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_board} alt="board" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_calendar} alt="calendar" />
                            </Carousel.Item>
                            <Carousel.Item >
                                <img className="img-fluid" src={app_cra} alt="cra" />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
                <div className="f-right wow fadeInUp" data-wow-delay="0.2s">
                    <div className="right-content">
                        <h2>{i18next.t("Activity planning, appointment management, and work time tracking")}</h2>
                        <p>{i18next.t("Organize tasks, schedule appointments, and track work hours effortlessly")}</p>
                        <ul>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Activity planning with a simple and intuitive visual board")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Appointment organization through an integrated calendar")}
                            </li>
                            <li>
                                <i className="feather icon-check-square" />
                                {i18next.t("Work time tracking with detailed timesheets")}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Features;
