import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import i18next from "i18next";

const TextWithAutocomplete = (props) => {
  const [isDirty, setIsDirty] = useState(false);
  const ref = useRef();

  const onChange = (e) => {
    if (e && e.length > 0) {
      props.handleChange(props.field, e[0]);
    }
    setIsDirty(true);
    //  else toogleMenu();
  };

  // const toogleMenu = () => ref.current?.toggleMenu(false);

  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      {props.title && (
        <Form.Label>
          {props.isRequired ? props.title + "*" : props.title}
        </Form.Label>
      )}
      <Typeahead
        ref={ref}
        style={{ fontSize: "var(--theme-font-size)" }}
        disabled={props.isReadOnly}
        dropup={false}
        flip={true}
        highlightOnlyResult={false}
        minLength={0}
        emptyLabel={i18next.t("No suggestion found")}
        // open={false}
        required={props.isRequired}
        selected={props.value ? [props.value] : []}
        onInputChange={(e) => props.handleChange(props.field, e)}
        onChange={(e) => onChange(e)}
        id={props.field}
        labelKey={props.field}
        options={props.options ? props.options : []}
        placeholder={props.placeholder}
        isInvalid={(props.dirty ?? isDirty) && props.isRequired && !props.value}
      />
      <Form.Control.Feedback type="invalid">
        {i18next.t("required field")}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default TextWithAutocomplete;
