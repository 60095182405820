import React from 'react';
import i18next from 'i18next';
import icon2 from '../assets/images/icon2.png';
import icon3 from '../assets/images/icon3.png';
import featureIcon1 from '../assets/images/feature_icon_1.png';
import featureIcon2 from '../assets/images/feature_icon_2.png';

const Why = () => {
    const sections = [
        {
            icon: featureIcon1,
            title: "All-in-one for freelancers",
            description: "Save time with a tool that centralizes the management of your projects, tasks, clients, and finances"
        }, {
            icon: featureIcon2,
            title: "Ease of use",
            description: "An intuitive interface designed to help you work efficiently without wasting time on complex features"
        }, {
            icon: icon2,
            title: "Productivity boost",
            description: "Automate your follow-ups, manage your quotes and invoices in a few clicks, and focus on what you do best your craft"
        }, {
            icon: icon3,
            title: "Designed by freelancers for freelancers",
            description: "Octolance understands your needs and provides solutions tailored to your everyday challenges"
        }
    ]

    return <div className="services-section text-center" id="benefits">
        <div className="container">
            <div className="row  justify-content-md-center">
                <div className="col-md-12 text-center">
                    <div className="services">
                        <div className="pricing-intro">
                            <h1 className="wow fadeInUp" data-wow-delay="0s">
                                {i18next.t("Why choose Octolance?")}
                            </h1>
                        </div>
                        <div className="row">
                            {sections.map(section => (
                                <div className={`col-sm-${12 / sections.length} wow fadeInUp`} data-wow-delay="0.2s">
                                    <div className="services-icon">
                                        <img src={section.icon} height="60" width="60" alt="Service" />
                                    </div>
                                    <div className="services-description">
                                        <h1>{i18next.t(section.title)}</h1>
                                        <p> {i18next.t(section.description)}</p>
                                    </div>
                                </div>))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Why;
