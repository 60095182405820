import React from "react";
import { Col, Row } from "react-bootstrap";
import Save from "../Forms/buttons/Save";
import Cancel from "../Forms/buttons/Cancel";
import Delete from "../Forms/buttons/Delete";

export const Footer = (props) => {
  const { save, cancel, remove } = props;

  return (
    <Row>
      <Col md={12}>
        <hr />
        {save &&
          <Save
            field={"save"}
            title={save.label}
            isReadOnly={save.isReadOnly}
            handleSave={save.handleSave}
          />}
        {cancel &&
          <Cancel
            field={"cancel"}
            title={cancel.label}
            isReadOnly={cancel.isReadOnly}
            handleCancel={cancel.handleCancel}
          />
        }
        {remove &&
          <span style={{ float: "left" }}>
            <Delete
              field={"delete"}
              title={remove.label}
              isReadOnly={remove.isReadOnly}
              handleChange={remove.handleDelete}
            />
          </span>}
      </Col>
    </Row>
  );
}

export default Footer;