import i18next from 'i18next';
import React, { useState } from 'react';
import FormGenerator from '../../../components/Forms/FormGenerator';
import { checkValidity } from '../../../../utils/input';
import ReCAPTCHA from 'react-google-recaptcha'
import { addAnonymousRequest } from '../../../../repositories/support';
import Loader from '../../../components/Loading/Loader';
import { dispatch } from '../../../../store';
import { setLoading } from '../../../../store/actions/common';
import { useSelector } from 'react-redux';
import { anonymousform, firstName, lastName, mail, message } from '../../../views/Support/Model';

const Contact = () => {
    const [item, setItem] = useState({});
    const locale = useSelector((state) => state.authentication.params.preferredLanguage);
    const [isDirty, setIsDirty] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [success, setSuccess] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");

    const onChange = (field, value) => {
        let changedData = { ...item };

        changedData[field] = value;
        setItem(changedData);
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (!recaptchaToken || !checkValidity([firstName, lastName, mail, message], item)) {
            setIsDirty(true);
            return;
        }
        try {
            dispatch(setLoading(true));
            await addAnonymousRequest(item, recaptchaToken);
            setSuccess(true);
            setErrorMessage("");
            setRecaptchaToken(null);
        } catch (error) {
            setSuccess(false);
            setErrorMessage(i18next.t("An error occurred Please try again later"));
        }
        finally {
            dispatch(setLoading(false));
        }
    };

    return <div className="cta-sub text-center no-color" id="contact">
        <div className="container">
            <h1 className="wow fadeInUp" data-wow-delay="0s">
                {i18next.t("Have a question or suggestion?")}
            </h1>
            <p className="wow fadeInUp" data-wow-delay="0.2s">
                {i18next.t("We are here to help! Feel free to reach out to us using the form below")}
                <br className="hidden-xs" />
                {i18next.t("Your feedback matters, and we are here to support you")} 😊
            </p>
            <div className="form wow fadeInUp" style={{
                width: 'fit-content',
                alignContent: 'center',
                textAlign: 'left',
                placeSelf: 'center'
            }} data-wow-delay="0.3s">
                <Loader>
                    {!success &&

                        (<><FormGenerator
                            form={anonymousform(isDirty)}
                            data={item}
                            handleChange={onChange}
                            index={-1}
                        />
                            <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} hl={locale} onChange={(token) => setRecaptchaToken(token)} />
                            <div
                                style={{
                                    color: "rgb(220, 53, 69)",
                                    marginTop: "5px",
                                    textAlign: "left",
                                }}
                            >
                                {errorMessage}
                            </div>
                            <form className="subscribe-form wow zoomIn">
                                <button className="submit-button" type="button" disabled={recaptchaToken ? false : true} onClick={onSubmit}>
                                    {i18next.t("Send")}
                                </button>
                            </form></>)}
                </Loader>
                {success && <div style={{
                    backgroundColor: "#d4edda",
                    color: "#155724",
                    border: "1px solid #c3e6cb",
                    borderRadius: "5px",
                    padding: "15px 20px",
                    fontSize: "1rem",
                    fontFamily: "Arial, sans-serif",
                    textAlign: "center",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    marginTop: "20px",
                    animation: "fadeIn 0.5s ease-in-out"
                }}>
                    {i18next.t("Your message has been sent successfully Thank you for reaching out to us")}
                </div>}
            </div>
        </div>
    </div>
};

export default Contact;
