import React from "react";
import { Form } from "react-bootstrap";

export const CheckboxInput = (props) => (
  <Form.Check
    {...props}
    style={{ fontSize: "var(--theme-font-size)" }}
    key={props.field}
    type="checkbox"
    label={props.isRequired ? props.title + "*" : props.title}
    checked={props.value ? props.value : false}
    onChange={(e) => props.handleChange(props.field, e.target.checked)}
    disabled={props.isReadOnly}
  />
);

const Checkbox = (props) => {
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      {CheckboxInput(props)}
    </Form.Group>
  );
};

export default Checkbox;
