import React from "react";
import i18next from "i18next";
import { Form, NavLink } from "react-bootstrap";
import { DeleteWhitoutFab, SyncIcon } from "../../Icons/icons";

const Recurring = (props) => {
  const { field, title, handleClick, handleDelete, asForm } = props;
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      <div style={{ display: "flex" }}>
        <NavLink
          style={asForm ? {
            fontSize: "var(--theme-font-size)",
            paddingTop: "35px",
            paddingLeft: "0px",
            paddingBottom: "0px",
            paddingRight: "0px",
          } : {}}
          id={"edit-" + field}
          onClick={() => handleClick(field)}
        >
          <SyncIcon /> {title}
        </NavLink>
        {handleDelete &&
          <span style={{
            cursor: "pointer",
            color: "#f44236",
            margin: "8px"
          }} onClick={() => handleDelete(field)}><DeleteWhitoutFab /> {i18next.t("Delete recurrence")}</span>}

      </div>
    </Form.Group>
  );
};

export default Recurring;
