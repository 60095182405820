import React, { useEffect, useState } from 'react';
import URLS from "../../../store/constants/urls";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import './assets/css/styles.scss';
// import Icon from "../../../assets/IconOnly_logo.svg";
import useKeycloakService from '../../../repositories/accessManagement/Keycloak';
import MaleAvatar from "../../../assets/images/user/user_undefined.png";
import FemaleAvatar from "../../../assets/images/user/user_undefined.png";
import { Menu } from '@material-ui/icons';
import Pricing from '../../components/Pricing';
import { getActivesProducts } from '../../../repositories/subscription';
import i18next from 'i18next';
import Main from './Components/main';
import Why from './Components/why';
// import Documentation from './Components/documentation';
import Features from './Components/features.js';
import MobileVersion from './Components/mobileVersion.js';
import Platforms from './Components/platforms.js';
import Footer from './Components/footer.js';
import Contact from './Components/contact.js';

const Landing = () => {
    let history = useHistory();
    const user = useSelector((state) => state.authentication.user.data);
    const { login, register, authenticated } = useKeycloakService();
    const [profilePicture, seProfilePicture] = useState(MaleAvatar);
    const [products, setProducts] = useState([]);
    const [show, setShow] = useState(false);
    const toggle = () => setShow(!show);

    useEffect(() => {
        getActivesProducts().then((response) => {
            setProducts(response);
        });
    }, [])

    useEffect(() => {
        seProfilePicture(
            user.pictureContent
                ? user.pictureContent
                : user.gender === "Male"
                    ? MaleAvatar
                    : FemaleAvatar
        );
        // eslint-disable-next-line
    }, [user]);

    const onFreeTrialClick = () => {
        if (!authenticated)
            register()
        else
            history.push(URLS.PROFILE_SUBSCRIPTION);
    }

    return (
        <React.Fragment>
            <div className="wrapper">
                <div>
                    <nav className="navbar landing-nav navbar-expand-lg navbar-light navbar-default navbar-fixed-top past-main" role="navigation">
                        <div className="container" style={{ marginTop: "8px" }}>
                            <button
                                className="navbar-toggler"
                                type="button"
                                data-toggle="collapse"
                                data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                onClick={toggle}
                            >
                                <Menu style={{ color: "white" }} />
                            </button>
                            <div className='flex-signin-mobile'>
                                {authenticated &&
                                    <div className="pro-head">
                                        <div class="row d-flex align-items-center">
                                            <div>
                                                <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                    {user.lastName}
                                                </span>
                                                <div style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                    {user.firstName}
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <a href={URLS.DASHBOARD_DEFAULT}>
                                                    <img
                                                        style={{ height: "40px", width: "40px" }}
                                                        className="rounded-circle"
                                                        src={profilePicture}
                                                        alt=""
                                                        role="presentation"
                                                        loading="lazy"
                                                    />
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                }
                                {!authenticated &&
                                    <a onClick={login} href={URLS.BLANK_LINK}>
                                        <div>
                                            <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                {i18next.t("Sign In")}
                                            </span>

                                        </div>
                                    </a>
                                }
                            </div>
                            <div className={show ? "collapse navbar-collapse show" : "collapse navbar-collapse"} id="navbarSupportedContent">
                                {/* <ul className="navbar-nav me-auto" /> */}
                                <ul className="navbar-nav my-2 my-lg-0">
                                    <li className="nav-item" onClick={toggle}>
                                        <a className="nav-link page-scroll" href="#main">
                                            {i18next.t("Home")}
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={toggle}>
                                        <a className="nav-link page-scroll" href="#benefits">
                                            {i18next.t("Benefits")}
                                        </a>
                                    </li>
                                    {/* <li className="nav-item dropdown" >
                                        <Link
                                            className="nav-link dropdown-toggle"
                                            to="#"
                                            id="navbarDropdown"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Dropdown
                                        </Link>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <Link to="#" className="dropdown-item">
                                                Level 1
                                            </Link>
                                            <Link to="#" className="dropdown-item">
                                                Level 2
                                            </Link>
                                            <Link to="#" className="dropdown-item">
                                                Level 3
                                            </Link>
                                        </div>
                                    </li> */}
                                    <li className="nav-item" onClick={toggle}>
                                        <a className="nav-link page-scroll" href="#features">
                                            {i18next.t("Features")}
                                        </a>
                                    </li>
                                    {/* <li className="nav-item" onClick={toggle}>
                                        <a className="nav-link page-scroll" href="#doc">
                                            {i18next.t("Documentation")}
                                        </a>
                                    </li> */}
                                    <li className="nav-item" onClick={toggle}>
                                        <a className="nav-link page-scroll" href="#pricing">
                                            {i18next.t("Pricing")}
                                        </a>
                                    </li>
                                    <li className="nav-item" onClick={toggle}>
                                        <a className="nav-link page-scroll" href="#contact">
                                            {i18next.t("Contact")}
                                        </a>
                                    </li>
                                </ul>
                                <div className='flex-signin-web'>
                                    {!authenticated &&
                                        <a onClick={login} href={URLS.BLANK_LINK}>
                                            <div>
                                                <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                    {i18next.t("Sign In")}
                                                </span>

                                            </div>
                                        </a>
                                    }
                                    {authenticated &&
                                        <div className="pro-head">
                                            <div class="row d-flex align-items-center">
                                                <div>
                                                    <span style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                        {user.lastName}
                                                    </span>
                                                    <div style={{ fontSize: "13px", fontWeight: "700", color: "#ffffff" }}>
                                                        {user.firstName}
                                                    </div>
                                                </div>
                                                <div class="col-auto">
                                                    <a href={URLS.DASHBOARD_DEFAULT}>
                                                        <img
                                                            style={{ height: "40px", width: "40px" }}
                                                            className="rounded-circle"
                                                            src={profilePicture}
                                                            alt=""
                                                            role="presentation"
                                                            loading="lazy"
                                                        />
                                                    </a>
                                                </div>

                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                    </nav>
                    <div className="main" id="main" onClick={() => setShow(false)}>
                        <Main handleClick={onFreeTrialClick} />
                        <Why />
                        <Features />
                        <div style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                            <Pricing products={products ?? []} isLandingPage={true} selectedProduct={{}} handleSelectChange={onFreeTrialClick} />
                        </div>
                        {/* <Documentation /> */}
                        <MobileVersion />

                        <Platforms />
                        <Contact />
                        <Footer />
                    </div>
                </div>
            </div >
        </React.Fragment >
    );
};

export default Landing;
