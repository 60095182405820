import { SET_OPTIONSET } from "../../constants/actions-types";

const initialState = {
  optionSet: [],
  // subProducts: [],
};

const handlers = {
  [SET_OPTIONSET](state, payload) {
    return {
      ...state,
      optionSet: payload,
    };
  },
  // [SET_SUBPRODUCTS](state, payload) {
  //   return {
  //     ...state,
  //     subProducts: payload,
  //   };
  // },
  // [SIGNOUT]() {
  //   return initialState;
  // },
};

const ConfigurationReducer = (state = initialState, { type, payload }) => {
  const actionHandler = handlers[type];
  if (actionHandler) {
    return actionHandler(state, payload);
  }
  return state;
};

export default ConfigurationReducer;
