import React from "react";
import i18next from "i18next";
import { Form } from "react-bootstrap";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { getTime, newDate } from "../../../../utils/date";
import { enGB, fr } from 'date-fns/locale';
import { fr as localFr, en as localEn } from '../../../../utils/constants';
import { useSelector } from "react-redux";

const locales = { [localFr]: fr, [localEn]: enGB };

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-input": {
        paddingTop: "0.8rem",
        paddingRight: "0.89rem",
        paddingBottom: "0.7rem",
        paddingLeft: "0.89rem",
        fontSize: "15px",
        color: "#66615b",
        lineHeight: "normal",
        backgroundColor: "#FFFFFF",
      },
    },
  },
});

export const isValidDate = (value, minDate) => {
  return !minDate || getTime(value) >= getTime(minDate);
};

const CustomDate = (props) => {
  const {
    field,
    title,
    isRequired,
    isReadOnly,
    value,
    defaultValue,
    handleChange,
    minDate,
    isForm = true
  } = props;
  const classes = useStyles();
  const [error, setError] = React.useState(null);

  const preferredLanguage = useSelector((state) => state.authentication.params.preferredLanguage);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case "maxDate":
      case "minDate": {
        return i18next.t("please choose a date greater than the start date");
      }
      case "maxTime":
      case "minTime": {
        return i18next.t("please choose a time greater than the start time");
      }
      case "invalidDate": {
        return i18next.t("your date is not valid");
      }

      default: {
        return "";
      }
    }
  }, [error]);

  if (isForm)
    return (
      <Form.Group key={"form" + field} controlid={"date-picker-inline" + field}>
        {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}

        <div style={{ width: "100%" }}>
          <LocalizationProvider adapterLocale={locales[preferredLanguage]} dateAdapter={AdapterDateFns}>
            <DatePicker
              className={classes.root}
              value={value ? newDate(value) : defaultValue ? defaultValue : null}
              onChange={(newValue) => handleChange(field, newValue)}
              // format="dd/MM/yyyy"
              disabled={isReadOnly}
              minDate={minDate ?? 0}
              slotProps={{
                field: { sx: { width: "100%" } },
                textField: {
                  error: (isRequired && !value) || !!errorMessage,
                  helperText: (isRequired && !value) ? i18next.t("required field") : errorMessage,
                },
              }}
              onError={(newError) => setError(newError)}
            // ampm={false}
            />
          </LocalizationProvider>
        </div>
      </Form.Group>)
  else
    return (<div style={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className={classes.root}
          value={value ? newDate(value) : defaultValue ? defaultValue : null}
          onChange={(newValue) => handleChange(field, newValue)}
          format="dd/MM/yyyy"
          disabled={isReadOnly}
          minDate={minDate ?? 0}
          slotProps={{
            field: { sx: { width: "100%" } },
            textField: {
              error: (isRequired && !value) || !!errorMessage,
              helperText: (isRequired && !value) ? i18next.t("required field") : errorMessage,
            },
          }}
          onError={(newError) => setError(newError)}
          ampm={false}
        />
      </LocalizationProvider>
    </div>
    );
};

export default CustomDate;
