import React from "react";
import i18next from "i18next";
import { Form } from "react-bootstrap";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { makeStyles } from "@material-ui/core/styles";
import "date-fns";
import { getMinutes, getTime, newDate } from "../../../../utils/date";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-input": {
        paddingTop: "0.6rem",
        paddingRight: "0.89rem",
        paddingBottom: "0.6rem",
        paddingLeft: "0.89rem",
        fontSize: "15px",
        color: "#66615b",
        lineHeight: "normal",
        backgroundColor: "#FFFFFF",
      },
    },
  },
});

export const isValidTime = (value, minDate) => {
  return (
    !minDate ||
    getTime(value) >=
    getTime(newDate(minDate).setMinutes(getMinutes(minDate) + 1))
  );
};

const CustomTime = (props) => {
  const {
    field,
    title,
    isRequired,
    isReadOnly,
    value,
    defaultValue,
    handleChange,
    minDate,
  } = props;
  const classes = useStyles();
  const [error, setError] = React.useState(null);

  const errorMessage = React.useMemo(() => {
    switch (error) {
      case "maxDate":
      case "minDate": {
        return i18next.t("please choose a date greater than the start date");
      }
      case "maxTime":
      case "minTime": {
        return i18next.t("please choose a time greater than the start time");
      }
      case "invalidDate": {
        return i18next.t("your date is not valid");
      }

      default: {
        return "";
      }
    }
  }, [error]);
  return (
    <Form.Group key={"form" + field} controlid={"date-picker-inline" + field}>
      {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          className={classes.root}
          value={value ? newDate(value) : defaultValue ? defaultValue : null}
          onChange={(newValue) => handleChange(field, newValue)}
          // format="dd/MM/yyyy"
          disabled={isReadOnly}
          minTime={
            minDate
              ? newDate(minDate).setMinutes(getMinutes(minDate) + 1)
              : 0
          }
          slotProps={{
            field: { sx: { width: "100%" } },
            textField: {
              error: (isRequired && !value) || !!errorMessage,
              helperText: (isRequired && !value) ? i18next.t("required field") : errorMessage,
            },
          }}
          onError={(newError) => setError(newError)}
          ampm={false}
        />
      </LocalizationProvider>
    </Form.Group>
  );
};

export default CustomTime;
