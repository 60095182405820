import React, { useState } from "react";
import { Form } from "react-bootstrap";
import i18next from "i18next";
import Clipboard from "../others/Clipboard";
import { InfoIcon } from "../../Icons/icons";

export const ascOrder = "ascOrder";
export const descOrder = "descOrder";

export const choiceInput = (field, isRequired, isReadOnly, placeholder, value, defaultValue, lookup, handleChange, sortBy, style, isDirty, setIsDirty, enableCopy) => {
  return <>
    <div style={{
      display: "flex",
      width: "100%"
    }}>
      <Form.Control
        style={{ ...{ fontSize: "var(--theme-font-size)" }, style }}
        key={field}
        required={isRequired}
        placeholder={placeholder}
        as="select"
        value={value ? value : ""}
        onChange={(e) => {
          if (setIsDirty) setIsDirty(true);
          handleChange(field, e.target.value);
        }}
        disabled={isReadOnly}
        isInvalid={isDirty && isRequired && !value}
      >
        <option key={-1} value="" disabled hidden>
          {placeholder ? placeholder : ""}
        </option>
        {!isRequired && <option key={0} value="" >
          {""}
        </option>}
        {defaultValue && (
          <option key={defaultValue.key}>{defaultValue.value}</option>
        )}
        {Object.keys(lookup)
          .sort((a, b) => {
            return sortBy === ascOrder
              ? a.toString().localeCompare(b.toString())
              : sortBy === descOrder
                ? b.toString().localeCompare(a.toString())
                : a; //no sort
          })
          .map((key) => renderChoiceOption(key, lookup[key]))}
      </Form.Control>
      {enableCopy && <Clipboard value={lookup[value]} />}
    </div>
    <Form.Control.Feedback type="invalid" style={isDirty && isRequired && !value ? { display: "flex" } : {}}>
      {i18next.t("required field")}
    </Form.Control.Feedback></>
}

const renderChoiceOption = (key, text) => {
  return (
    <option key={key} value={key}>
      {text}
    </option>
  );
};

const Choice = (props) => {
  const {
    field,
    title,
    value,
    isRequired,
    lookup,
    defaultValue,
    isReadOnly,
    placeholder,
    handleChange,
    sortBy = ascOrder,
    style,
    dirty,
    enableCopy,
    info
  } = props;

  const [isDirty, setIsDirty] = useState(false);
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      {title && (
        <Form.Label>
          {isRequired ? title + "*" : title}
        </Form.Label>
      )}
      {info && <span style={{
        position: "relative",
        top: "-1px"
      }}>
        <InfoIcon tooltip={info} /></span>}
      {choiceInput(field, isRequired, isReadOnly, placeholder, value, defaultValue, lookup, handleChange, sortBy, style, dirty ?? isDirty, setIsDirty, enableCopy)}
    </Form.Group>
  );
};



export default Choice;
