import React, { useEffect, useState } from "react";
import { FormGroup, Label, InputGroup } from "reactstrap";
import { NavLink } from "react-router-dom";
import { choiceInput } from "./Choice";
import { textInput } from "./Text";
import { formType } from "../forms";
import { v4 } from "uuid";
import i18next from "i18next";
import { DeleteIcon } from "../../Icons/icons";

const splitter = "_";

export default function GroupedInputs(props) {
  const { field, title, inputs, handleChange, isRequired, addnewTitle, value } =
    props;

  const [inputfields, setInputFields] = useState({});

  useEffect(() => {
    if (value && value.length > 0) {
      const existingInputs = { ...inputfields };
      value.forEach(
        (v) =>
        (existingInputs[v4()] = Object.keys(inputs).map((key) => ({
          field: key,
          value: v[key],
        })))
      );
      setInputFields(existingInputs);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const newInputs = [];
    Object.values(inputfields).forEach((element) =>
      newInputs.push(
        element.reduce((a, v) => ({ ...a, [v.field]: v.value }), {})
      )
    );
    handleChange(field, newInputs);
    // eslint-disable-next-line
  }, [inputfields]);

  const onChangeValue = (field, value) => {
    const editedInputfields = { ...inputfields };
    const key = field?.split(splitter)[0];
    const item = editedInputfields[key].find(
      (e) => e.field === field?.split(splitter)[1]
    );
    item.value = value;

    setInputFields(editedInputfields);
  };

  const onDelete = (key) => {
    const editedfilterfields = { ...inputfields };
    delete editedfilterfields[key.split(splitter)[0]];
    setInputFields(editedfilterfields);
  };

  const filterComponents = (key, field, value) => {
    switch (inputs[field]?.type) {
      case formType.text:
        return textInput(
          key + splitter + field,
          true,
          false,
          inputs[field].display,
          value,
          onChangeValue,
          {
            ...{ borderRight: "solid 3px #ccc" },
            ...(inputs[field].style ?? {}),
          }
        );
      case formType.choice:
        return choiceInput(
          key + splitter + field,
          true,
          false,
          i18next.t("Select") + inputs[field].display + " ...",
          value,
          undefined,
          inputs[field].options,
          onChangeValue,
          undefined,
          { ...{ borderRight: "solid 3px #ccc" }, ...inputs[field].style }
        );
      default:
        return <React.Fragment />;
    }
  };

  return (
    <FormGroup key={"form" + field} controlid={field}>
      <div>{title && <Label>{isRequired ? title + "*" : title}</Label>}</div>
      {Object.keys(inputfields).map((key) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InputGroup>
              {inputfields[key].map((i) =>
                filterComponents(key, i.field, i.value)
              )}
            </InputGroup>
            <div
              style={{
                paddingLeft: "20px",
              }}
            >
              <DeleteIcon
                style={{ fontSize: "25px", paddingBottom: "10px" }}
                onClick={() => onDelete(key + "_delete")}
              />
            </div>
          </div>
        );
      })}
      <div>
        <NavLink
          title={addnewTitle ?? i18next.t("Add filter")}
          action={() =>
            setInputFields({
              ...inputfields,
              ...{
                [v4()]: Object.keys(inputs).map((key) => ({
                  field: key,
                  value: "",
                })),
              },
            })
          }
        />
      </div>
    </FormGroup>
  );
}
