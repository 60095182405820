import React, { useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import i18next from "i18next";
import { DeleteWhitoutFab as DeleteIcon } from "../../Icons/icons";

const Upload = (props) => {
  const { field, title, isRequired, value, placeholder, handleChange, dirty, error } = props;
  const [isDirty, setIsDirty] = useState(false);

  return (
    <Form.Group key={"form" + field} controlId={field}>
      {title && (
        <Form.Label>
          {" "}
          {isRequired ? title + "*" : title}
        </Form.Label>
      )}
      <Row>
        <Col md={8}>
          <Form.File
            style={{ fontSize: "var(--theme-font-size)", padding: "10px 20px" }}
            // id="custom-file-translate-scss"
            label={value ? value : placeholder}
            lang={"en"}
            custom
            onChange={(e) => {
              setIsDirty(true);
              handleChange(field, e.target.files[0]);
            }}
            isInvalid={
              (dirty ?? isDirty) &&
              ((isRequired && !value) || error)
            }
          />
          {
            <div
              className="error"
              style={{
                marginTop: "5px",
                textAlign: "left",
              }}
            >
              {error}
            </div>
          }
          <Form.Control.Feedback type="invalid">
            {isRequired && !value && i18next.t("required field")}
          </Form.Control.Feedback>
        </Col>
        {value !== placeholder && (
          <Col md={4} style={{ top: "4px" }}>
            <DeleteIcon
              onClick={(e) => {
                handleChange(field, null);
              }}
            />
          </Col>
        )}
      </Row>
    </Form.Group>
  );
};

export default Upload;
