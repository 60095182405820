import React from "react";
import Button from "react-bootstrap/Button";

const Submit = (props) => {
  return (
    <Button
      key={"form" + props.field}
      type="submit"
      variant="primary"
      className="float-right"
      disabled={props.isReadOnly}
    >
      {props.title}
    </Button>
  );
};

export default Submit;
