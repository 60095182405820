import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import Loadable from "react-loadable";
import i18n from "../i18n";
import { I18nextProvider } from "react-i18next";
import "../../node_modules/font-awesome/scss/font-awesome.scss";
import Loader from "./components/Loader";
import Aux from "../hoc/_Aux";
import ScrollToTop from "./components/ScrollToTop";
import routes from "../route";
import Notification from "./components/Notification/index";
import { dispatch } from "../store";
import { setUserAbility } from "../store/actions/authentication";
import { defineRulesFor } from "../repositories/authorization";
import LandingPage from "./layout/Landing";
import useKeycloakService from "../repositories/accessManagement/Keycloak";
import URLS from "../store/constants/urls";
import { fetchOptionSet } from "../store/actions/optionSet";

const AdminLayout = Loadable({
  loader: () => import("./layout/AdminLayout"),
  loading: Loader,
});

const App = () => {
  const { authenticated } = useKeycloakService();
  const locale = useSelector((state) => state.authentication.params.preferredLanguage);
  const optionSet = useSelector((state) => state.configuration.optionSet);
  // const prevOptionSet = usePrevious(optionSet);
  const permissions = useSelector(
    (state) => state.authentication.user.permissions
  );

  const getComponentToshow = () => {
    if (authenticated) {
      return AdminLayout;
    } else {
      return () => <LandingPage />;
    }
  };

  useEffect(() => {
    if (permissions.length > 0)
      dispatch(setUserAbility(defineRulesFor(permissions)));
    // eslint-disable-next-line
  }, [permissions]);

  useEffect(() => {
    if (optionSet.length === 0) {
      dispatch(fetchOptionSet())
    }
    // eslint-disable-next-line
  }, [optionSet]);

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null;
  });

  return (
    <Aux>
      <I18nextProvider i18n={i18n(locale)}>
        <Notification />
        <ScrollToTop>
          <Suspense fallback={<Loader />}>
            <Switch>
              {menu}
              <Route path={URLS.SOURCE} component={getComponentToshow()} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </I18nextProvider>
    </Aux>
  );
};

export default App;
