export const fr = "fr";
export const en = "en";
export const english = "English";
export const français = "Français";
export const paid = "paid";
export const unpaid = "unpaid";

export const bilingual = 2;
export const native = 3;

export const firstContact = "First Contact";
export const positionned = "Positionned";
export const onWaiting = "On Waiting";
export const aborted = "Aborted";
export const accepted = "Accepted";

export const basic = 1;
export const familiar = 2;
export const senior = 3;
export const expert = 4;

export const functionnal = "functionnal";
export const technical = "technical";
export const tool = "tool";
export const other = "other";
export const scheduled = "scheduled";

export const male = "male";
export const female = "female";

export const bill = "bill";
export const urssaf = "urssaf";
export const insurance = "insurance";
export const cpf = "cpf";
export const bankCharges = "bankCharges";
export const In = "in";
export const out = "out";
export const vat = "vat";
export const taxes = "taxes";

export const caHt = "caHt";
export const caTtc = "caTtc";

export const reminder = "reminder";
export const autoInput = "autoInput";

export const delta = "delta";
export const specificInterval = "specificInterval";

export const fixed = "fixed";
export const calculated = "calculated";

export const preferredLanguagesLookup = { [fr]: français, [en]: english };
export const billStateLookup = () => ({
  [paid]: "Paid",
  [unpaid]: "Unpaid",
});
export const cvSkillsTypeLookup = () => ({
  [functionnal]: "Functionnal",
  [technical]: "Technical",
  [tool]: "Tool",
  [other]: "Other",
});
export const cvLanguageLevelLookup = () => ({
  [bilingual]: "Bilingual",
  [native]: "Native",
});
export const offerStatusLookup = () => ({
  [firstContact]: "First Contact",
  [positionned]: "Positionned",
  [onWaiting]: "On Waiting",
  [aborted]: "Aborted",
  [accepted]: "Accepted",
});
export const cvSkillsLevelLookup = () => ({
  [basic]: "Basic",
  [familiar]: "Familiar",
  [senior]: "Senior",
  [expert]: "Expert",
});
export const profileGenderLookup = () => ({
  [male]: "Male",
  [female]: "Female",
});

export const transactionTypeLookup = () => ({
  [bill]: "Bill",
  [vat]: "Vat",
  [scheduled]: "Scheduled",
});

export const transactionOperationLookup = () => ({
  [In]: "In",
  [out]: "Out",
});

export const amountTypeLookup = () => ({
  [fixed]: "Fixed amount",
  [calculated]: "Calculated amount",
});

export const operationSourceLookup = () => ({
  [caTtc]: "Turnover including tax",
  [caHt]: "Turnover excluding taxes",
});

export const actionLookup = (operation) => ({
  [reminder]: "Debit with delay",
  [autoInput]: "This transaction is automatically {operation} to my account".replace("{operation}", operation === In ? "credited" : "debited")
});

export const intervalLookup = () => ({
  [delta]: "Since last successful execution",
  [specificInterval]: "Specific interval",
});

export const subProductType_recurring = "recurring";
export const subProductType_oneTime = "one_time";

export const subProductInterval_day = "day";
export const subProductInterval_week = "week";
export const subProductInterval_month = "month";
export const subProductInterval_year = "year";

export const subProductType = () => ({
  [subProductType_oneTime]: "One-off",
  [subProductType_recurring]: "Recurring",
})

export const subProductInterval = () => ({
  [subProductInterval_day]: "day",
  [subProductInterval_week]: "week",
  [subProductInterval_month]: "month",
  [subProductInterval_year]: "year",
})

export const frenshImpots = (revenu) => {
  const bareme = {
    0: 10777,
    11: 27478,
    30: 78570,
    41: 168994,
    45: 168995,
  };
  let impot = 0;
  let previousLevel = 0;
  Object.keys(bareme).every((level) => {
    impot +=
      (level *
        ((revenu > bareme[level] ? bareme[level] : revenu) -
          bareme[previousLevel])) /
      100;
    previousLevel = level;
    if (revenu < bareme[level]) return false;
    return true;
  });

  return impot;
};

export const allColors = [
  "#1de9b6",
  '#008B8B',
  "#007FFF",
  '#9966CC',
  '#FF91AF',
  '#dc3545',
  '#FF0800',
  '#FF9966',
  '#DDD06A',
  '#993300',
  '#FF66CC',
  '#FDBCB4',
  '#536878',
  '#B2BEB5',
  '#C1F85C',
  '#AA98A9',
  '#36454F',
]

export const severityColor = {
  "warning": "#ffc107",
  "error": "#dc3545"
}