import React, { useState } from "react";
import { Form } from "react-bootstrap";
import i18next from "i18next";

export const isValidEmail = (value) => {
  return new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(value.trim());
};

const Email = (props) => {
  const [isDirty, setIsDirty] = useState(false);
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      {props.title && (
        <Form.Label>
          {" "}
          {props.isRequired ? props.title + "*" : props.title}
        </Form.Label>
      )}
      <Form.Control
        style={{ fontSize: "var(--theme-font-size)" }}
        key={props.field}
        required={props.isRequired}
        type="email"
        placeholder={props.placeholder}
        value={props.value ? props.value : ""}
        onChange={(e) => {
          setIsDirty(true);
          props.handleChange(props.field, e.target.value);
        }}
        disabled={props.isReadOnly}
        isInvalid={
          (props.dirty ?? isDirty) &&
          ((props.isRequired && !props.value) || !isValidEmail(props.value))
        }
      />
      <Form.Control.Feedback type="invalid">
        {props.isRequired && !props.value && i18next.t("required field")}
        {props.value && i18next.t("enter a valid email address")}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default Email;
