import React from 'react';
import { Carousel } from 'react-bootstrap';
import linkedinLogo from '../assets/images/linkedIn.svg';
import youtubeLogo from '../assets/images/youtube.svg';
import facebooklogo from '../assets/images/facebook.svg';
import instagramLogo from '../assets/images/instagram.svg';

const Platforms = () => {
    return <div className="client-section">
        <div className="container text-center">
            <Carousel className=" owl-carousel owl-theme" interval={1000}>
                <Carousel.Item className="single">
                    <img src={linkedinLogo} alt="linkedin" />
                </Carousel.Item>
                <Carousel.Item className="single">
                    <img src={youtubeLogo} alt="youtube" />
                </Carousel.Item>
                <Carousel.Item className="single">
                    <img src={facebooklogo} alt="facebook" />
                </Carousel.Item>
                <Carousel.Item className="single">
                    <img src={instagramLogo} alt="instagram" />
                </Carousel.Item>
            </Carousel>
        </div>
    </div>
};

export default Platforms;
