import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import parsePhoneNumber from 'libphonenumber-js'

export const isValidPhone = (value) => {
  return new RegExp(
    /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  ).test(value.trim());
};

export const formatPhoneNumber = (value) => {
  const phoneNumber = parsePhoneNumber(value?.includes('+') ? value : `+${value}`)
  return phoneNumber ? phoneNumber.formatInternational() : value
}

export const phoneInput = (
  field,
  isRequired,
  isReadOnly,
  placeholder,
  value,
  handleChange,
  style,
  isDirty,
  setIsDirty
) => {
  return (
    <>
      <PhoneInput
        inputStyle={{ fontSize: "var(--theme-font-size)", paddingTop: "20px", paddingBottom: "20px", width: "100%", ...style }}
        inputClass="form-control"
        value={value ? value : ""}
        disabled={isReadOnly}
        placeholder={placeholder}
        onChange={(value) => {
          setIsDirty(true);
          handleChange(field, value);
        }}
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true
        }}
        isValid={(value) => {
          if (isDirty && isRequired && !value) {
            return false
          } else if (isDirty && value && !isValidPhone(value)) {
            return false;
          } else {
            return true;
          }
        }}
      />
      <text className="text-error">
        {isDirty && isRequired && !value && i18next.t("required field")}
        {isDirty && value && !isValidPhone(value) && i18next.t("enter a valid phone number")}
      </text>
    </>
  );
};

const Phone = (props) => {
  const {
    field,
    title,
    isRequired,
    value,
    isReadOnly,
    placeholder,
    handleChange,
    style,
    dirty,
  } = props;
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (value) {
      const phoneInputInstance = document.querySelector('.form-control input');
      if (phoneInputInstance) {
        // Manually trigger the onChange event to detect the country
        const event = new Event('change', { bubbles: true });
        phoneInputInstance.dispatchEvent(event);
      }
    }
  }, [value]);

  const onChange = (field, value) => {
    handleChange(field, value)
  }

  return (
    <Form.Group key={"form" + field} controlId={field}>
      {props.title && (
        <Form.Label>{isRequired ? title + "*" : title}</Form.Label>
      )}
      {
        phoneInput(
          field,
          isRequired,
          isReadOnly,
          placeholder,
          value,
          onChange,
          style,
          dirty ?? isDirty,
          setIsDirty
        )
      }
    </Form.Group>
  );
};

export default Phone;
