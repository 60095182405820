import React from "react";
import { Form, ListGroup } from "react-bootstrap";

const CustomListGroup = (props) => {
  const { field, title, isRequired, value, handleChange, style } = props;

  return (
    <Form.Group key={"form" + field} controlId={field}>
      {props.title && (
        <Form.Label>{isRequired ? title + "*" : title}</Form.Label>
      )}
      {value && value.length > 0 && (
        <ListGroup as="ol" variant="flush">
          {value.map((v, i) => (
            <ListGroup.Item
              style={style}
              action={handleChange ? true : false}
              onClick={() => (handleChange ? handleChange(field, i) : false)}
            >
              {v}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
    </Form.Group>
  );
};

export default CustomListGroup;
