import React from "react";
import { Form } from "react-bootstrap";

const Component = (props) => {
  return (
    <Form.Group key={"form" + props.field} controlId={props.field} style={props.style}>
      {props.title && (
        <Form.Label>{props.isRequired ? props.title + "*" : props.title}</Form.Label>
      )}
      {props.content}
    </Form.Group>
  );
};

export default Component;
