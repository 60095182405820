import React from "react";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
export const dialogType = {
  success: "success",
  fail: "fail",
  medium: "md",
  large: "large",
  xlarge: "xlarge",
  override: "override",
  delete: "delete",
};

export const Dialog = (props) => {
  const { title, type, component, handleClose, backgroundImage, headerless } = props
  let editedTitle = title;
  let size = dialogType.medium;
  let centered = false;
  switch (type) {
    case dialogType.success:
      size = "md";
      editedTitle = (
        <React.Fragment>
          <Image
            src={require("../../../assets/images/success.png")}
            roundedCircle
          />{" "}
          {editedTitle}
        </React.Fragment>
      );
      break;
    case dialogType.fail:
      size = "md";
      editedTitle = (
        <React.Fragment>
          <Image
            src={require("../../../assets/images/fail.png")}
            roundedCircle
          />{" "}
          {editedTitle}
        </React.Fragment>
      );
      break;
    case dialogType.large:
      size = "lg";
      centered = true;
      break;
    case dialogType.xlarge:
      size = "xl";
      centered = true;
      break;
    default:
      centered = true;
  }

  return (
    <Modal
      show={true}
      size={size}
      onHide={() => false}
      aria-labelledby="contained-modal-vcenter"
      centered={centered}
    >
      {!headerless && (
        <Modal.Header closeButton={handleClose} onClick={() => handleClose()}>
          <Modal.Title id="contained-modal-vcenter">{editedTitle}</Modal.Title>
        </Modal.Header>)}
      {component && <Modal.Body style={backgroundImage ? {
        backgroundImage: "url(" + backgroundImage + ")", "backgroundRepeat": "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "right"
      } : {}}>{component}</Modal.Body>}
    </Modal>
  );
};
