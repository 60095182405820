import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import i18next from "i18next";
import { InfoIcon } from "../../Icons/icons";

export const numericInput = (field, isRequired, isReadOnly, placeholder, value, handleChange, isDirty, setIsDirty, label) => {
  return label ?
    <div style={{
      display: "flex",
      width: "100%"
    }}> <InputGroup className="mb-2" style={{ background: "#ffffff" }} >
        <Form.Control
          style={{ fontSize: "var(--theme-font-size)" }}
          key={field}
          type="number"
          required={isRequired}
          // value={value}
          value={value ? value : ""}
          onChange={(e) => {
            if (setIsDirty) setIsDirty(true);
            handleChange(
              field,
              e.target.value
            );
          }}
          disabled={isReadOnly}
          placeholder={placeholder}
          // isInvalid={isRequired && (!value || value < 0)}
          isInvalid={isDirty && isRequired && (!value || value === "" || parseInt(value) <= 0)
          }
        />
        <InputGroup.Prepend style={{
          borderTopRightRadius: "4px",
          borderBottomRightRadius: "4px",
          background: "gainsboro"
        }} >
          <InputGroup.Text style={{
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            background: "gainsboro"
          }}>{label}</InputGroup.Text>
        </InputGroup.Prepend>
        <Form.Control.Feedback type="invalid">
          {i18next.t("required field")}
        </Form.Control.Feedback>
      </InputGroup>
    </div>
    :
    <div style={{
      display: "flex",
      width: "100%"
    }}>
      <Form.Control
        style={{ fontSize: "var(--theme-font-size)" }}
        key={field}
        type="number"
        required={isRequired}
        //value={value}
        value={value ? value : ""}
        onChange={(e) => {
          if (setIsDirty) setIsDirty(true);
          handleChange(
            field,
            e.target.value
          );
        }}
        disabled={isReadOnly}
        placeholder={placeholder}
        // isInvalid={isRequired && (!value || value < 0)}
        isInvalid={
          isDirty && isRequired && (!value || value === "" || parseInt(value) <= 0)
        }
      />
      <Form.Control.Feedback type="invalid">
        {i18next.t("required field")}
      </Form.Control.Feedback>
    </div>
}

const Numeric = (props) => {
  const {
    field,
    title,
    isReadOnly,
    isRequired,
    value,
    placeholder,
    handleChange,
    dirty,
    label,
    info
  } = props;

  const [isDirty, setIsDirty] = useState(false);

  return (<Form.Group key={"form" + field} controlId={field}>
    {props.title && (
      <div>
        <Form.Label>
          {isRequired ? title + "*" : title}
        </Form.Label>
        {info && <span style={{
          position: "relative",
          top: "-1px"
        }}>
          <InfoIcon tooltip={info} /></span>}
      </div>
    )}
    {numericInput(field, isRequired, isReadOnly, placeholder, value, handleChange, dirty ?? isDirty, setIsDirty, label)}
  </Form.Group>);

};

export default Numeric;
