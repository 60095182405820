import { formType } from "../../components/Forms/forms";
import { withTranslations } from "../../../utils/translateProxyHandler ";
import { getDateTime } from "../../../utils/date";

export const firstName = withTranslations({
  field: "firstName",
  title: "First Name",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
  placeholder: "First Name",
});

export const lastName = withTranslations({
  field: "lastName",
  title: "Last Name",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
  placeholder: "Last Name",
});

export const mail = withTranslations({
  field: "mail",
  title: "Email",
  formType: formType.email,
  isRequired: true,
  isReadOnly: false,
  placeholder: "Email",
});

export const message = withTranslations({
  field: "message",
  title: "Message",
  formType: formType.description,
  isRequired: true,
  isReadOnly: false,
  placeholder: "Enter your message (max 1000 characters)",
  maxLength: 1000,
  rows: 6
});

export const creationDate = withTranslations({
  field: "creationDate",
  title: "Creation date",
  formType: formType.datetime,
  isRequired: false,
  isReadOnly: false,
  render: (rowData) => getDateTime(rowData.creationDate)
});

export const lastModificationDate = withTranslations({
  field: "lastModificationDate",
  title: "Last Modification Date",
  formType: formType.datetime,
  isRequired: false,
  isReadOnly: false,
  render: (rowData) => getDateTime(rowData.lastModificationDate)
});

export const state = withTranslations({
  field: "state",
  title: "State",
  formType: formType.choice,
  lookup: { "treated": "Treated", "notTreated": "Not treated" },
  isRequired: true,
  isReadOnly: false,
});


export const submit = withTranslations({
  title: "Save",
  formType: formType.submit,
});

export const cancel = withTranslations({
  title: "Cancel",
  formType: formType.cancel,
});

export const anonymousform = (isDirty) => {
  return {
    rows: [
      {
        columns: [{ size: 6, render: { ...firstName, dirty: isDirty } }, { size: 6, render: { ...lastName, dirty: isDirty } }],
      },
      {
        columns: [{ size: 12, render: { ...mail, dirty: isDirty } }],
      },
      {
        columns: [{ size: 12, render: { ...message, dirty: isDirty } }],
      },
    ]
  };
}

export const newform = (isDirty) => {
  return {
    rows: [
      {
        columns: [{ size: 4, render: { ...firstName, dirty: isDirty } }, { size: 4, render: { ...lastName, dirty: isDirty } }, { size: 4, render: { ...state, dirty: isDirty } }],
      },
      {
        columns: [{ size: 12, render: { ...mail, dirty: isDirty } }],
      },
      {
        columns: [{ size: 12, render: { ...message, dirty: isDirty } }],
      },
    ],
    footer: [{ render: submit }, { render: cancel }]
  };
}

export const form = (isDirty) => {
  return {
    rows: [
      {
        columns: [{ size: 4, render: { ...firstName, dirty: isDirty } }, { size: 4, render: { ...lastName, dirty: isDirty } }, { size: 4, render: { ...state, dirty: isDirty } }],
      },
      {
        columns: [{ size: 12, render: { ...mail, dirty: isDirty } }],
      },
      {
        columns: [{ size: 12, render: { ...message, dirty: isDirty } }],
      },
      {
        columns: [{ size: 6, render: { ...creationDate, dirty: isDirty } }, { size: 6, render: { ...lastModificationDate, dirty: isDirty } }],
      }
    ],
    footer: [{ render: submit }, { render: cancel }],
  };
}
