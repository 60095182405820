import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForOffers = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.OFFERS);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.OFFERS);
    });
  }
};

export const canAddOffer = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.OFFER_ADD, subjects.OFFERS);

export const canUpdateOffer = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.OFFER_UPDATE, subjects.OFFERS);

export const canDeleteOffer = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.OFFER_DELETE, subjects.OFFERS);
