import React, { useState } from "react";
import { Form } from "react-bootstrap";
import InputGroup from "react-bootstrap/InputGroup";
import i18next from "i18next";

const ChoiceAddons = (props) => {
  const { field, title, isRequired, isReadOnly, placeholder, lookup, value, defaultValue, handleChange, addOnsComponent, dirty } = props;
  const [isDirty, setIsDirty] = useState(false);
  return (
    <Form.Group key={"form" + field} controlId={field}>
      {title && (
        <Form.Label>
          {isRequired ? title + "*" : title}
        </Form.Label>
      )}
      <InputGroup >
        <Form.Control
          style={{ fontSize: "var(--theme-font-size)" }}
          key={field}
          required={isRequired}
          placeholder={placeholder}
          as="select"
          value={value ? value : ""}
          onChange={(e) => {
            setIsDirty(true);
            handleChange(field, e.target.value);
          }}
          disabled={isReadOnly}
          isInvalid={(dirty ?? isDirty) && isRequired && !value}
        >
          <option key={-1} value="" disabled selected hidden>
            {placeholder ? placeholder : ""}
          </option>
          {!isRequired && <option key={0} value="" >
            {""}
          </option>}
          {defaultValue && (
            <option key={defaultValue.key}>{defaultValue.value}</option>
          )}

          {Object.keys(lookup)
            .sort(function (a, b) {
              return b - a;
            })
            .map((key) => renderChoiceOption(key, lookup[key]))}
        </Form.Control>
        <InputGroup.Append>
          {addOnsComponent}
        </InputGroup.Append>
      </InputGroup>
      <Form.Control.Feedback type="invalid" style={(dirty ?? isDirty) && isRequired && !value ? { display: "flex" } : {}}>
        {i18next.t("required field")}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

const renderChoiceOption = (key, text) => {
  return (
    <option key={key} value={key}>
      {text}
    </option>
  );
};

export default ChoiceAddons;
