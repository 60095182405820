import React from 'react';
import { copyrightText } from '../../../../utils/layout';
import logo from "../../../../assets/logo.svg";
import { useHistory } from 'react-router-dom';
import URLS from "../../../../store/constants/urls";
import facebookLogo from '../assets/images/facebook-logo.svg';
import linkedinLogo from '../assets/images/linkedin-logo.svg';
import youtubeLogo from '../assets/images/youtube-logo.svg';
import instagramLogo from '../assets/images/instagram-logo.svg';

const Footer = () => {
    let history = useHistory();

    return <div className="footer">
        <div className="container">
            <div className="col-md-12 text-center">
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}>
                    <img src={logo} alt="Octolance full logo" style={{
                        height: "180px",
                        margin: 0
                    }} />

                    <ul className="style-socialList" style={{ alignContent: "center" }}>
                        <li key={"Linkedin"}>
                            <a href="https://www.linkedin.com/octolance/" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                <img className="styles-socialIcon" src={linkedinLogo} alt="Linkedin" />
                            </a>
                        </li>
                        <li key={"Youtube"}>
                            <a href="https://www.youtube.com/octolance/" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                <img className="styles-socialIcon" src={youtubeLogo} alt="Youtube" />
                            </a>
                        </li>
                        <li key={"Facebook"}>
                            <a href="https://www.facebook.com/octolance/" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                <img className="styles-socialIcon" src={facebookLogo} alt="Facebook" />
                            </a>
                        </li>
                        <li key={"Instagram"}>
                            <a href="https://www.instagram.com/octolance/" className="styles-socialLink" rel="noopener noreferrer" target="_blank">
                                <img className="styles-socialIcon" src={instagramLogo} alt="Instagram" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="footer-text">
                    <ul className="footer-menu">
                        <li>
                            <a onClick={() => history.push(URLS.CGV)} href={URLS.CGV}>CGV</a>
                        </li>
                        <li>
                            <a onClick={() => history.push(URLS.CGU)} href={URLS.CGU}>CGU</a>
                        </li>
                    </ul>
                    <p> {copyrightText()}</p>
                </div>
            </div>
        </div>
    </div>
};

export default Footer;
