import React, { useState } from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import { components, default as ReactSelect } from "react-select";
import { Form } from "react-bootstrap";
import makeAnimated from "react-select/animated";
import { useEffect } from "react";

const MultiChoice = (props) => {
  const {
    field,
    title,
    isRequired,
    isReadOnly,
    value,
    options,
    handleChange,
    withCheckbox = true,
    dirty,
    placeholder
  } = props;

  const [isDirty, setIsDirty] = useState(false);
  useEffect(
    () => setIsDirty(dirty),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty]
  );
  const alloptions = {
    label: i18next.t("Select all"),
    value: "*",
  };
  const animatedComponents = makeAnimated();
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          {withCheckbox && (
            <span style={{ paddingRight: "5px" }}>
              <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null}
              />
            </span>
          )}
          {props.label}
        </components.Option>
      </div>
    );
  };
  const MultiValue = (props) => (
    <components.MultiValue {...props}>
      <span>{props.data.label}</span>
    </components.MultiValue>
  );

  if (!isReadOnly)
    return (
      <Form.Group key={"form" + field} controlId={field}>
        {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}

        <ReactSelect
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor:
                isDirty && isRequired && (!value || value.length === 0)
                  ? "#dc3545"
                  : baseStyles.borderColor,
            }),
            menu: (base) => ({
              ...base,
              zIndex: 9000,
            }),
          }}
          options={
            options && options.length > 0 ? [alloptions, ...options] : options
          }
          isMulti
          hideSelectedOptions={false}
          components={{
            Option,
            MultiValue,
            animatedComponents,
          }}
          closeMenuOnSelect={false}
          onChange={(selected) => {
            if (
              selected !== null &&
              selected.length > 0 &&
              selected[selected.length - 1].value === alloptions.value
            ) {
              setIsDirty(true);
              return handleChange(
                field,
                options.map((opt) => opt.value)
              );
            }
            setIsDirty(true);
            return handleChange(
              field,
              selected
                .filter((s) => s.value !== alloptions.value)
                .map((opt) => opt.value)
            );
          }}
          allowSelectAll={true}
          value={
            value
              ? value.length === options.length
                ? [alloptions, ...options]
                : value.map((v) => options.find((o) => o.value === v))
              : []
          }
          required={isRequired}
          placeholder={placeholder ?? i18next.t("Select") + "..."}
        />
        {isDirty && isRequired && (!value || value.length === 0) && (
          <text className="text-error">
            {i18next.t("required field")}
          </text>
        )}
      </Form.Group>
    );
  else
    return (
      <Form.Group key={"form" + field} controlId={field}>
        {title && <Form.Label>{title}</Form.Label>}
        <Form.Control
          key={field}
          type="text"
          required={isRequired}
          value={value ? value : ""}
          disabled={true}
          isInvalid={isDirty && isRequired && !value}
        />
      </Form.Group>
    );
};

MultiChoice.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default MultiChoice;
