import React from 'react';
import i18next from 'i18next';
import backIphone from '../assets/images/iphone_hand.png';

const MobileVersion = () => {
    return <div className="features-section">
        <div className="f-left">
            <div className="left-content wow fadeInLeft" data-wow-delay="0s">
                <div className="pricing-intro">
                    <h1 className="wow fadeInUp" data-wow-delay="0s">
                        {i18next.t("A fully responsive interface, optimized for use on mobile and tablet")}
                    </h1>
                </div>
                <p className="wow fadeInLeft" data-wow-delay="0.2s">
                    {`${i18next.t("With Octolancecom, access your dashboard and all its features anytime, anywhere")} ${i18next.t("Create invoices, manage transactions, track real-time statistics, and enjoy seamless access to everything you need, right from your mobile device!")}`}
                </p>
            </div>
        </div>
        <div
            className="f-right"
            style={{ backgroundImage: `url(${backIphone})`, backgroundPosition: '50% 50%', backgroundSize: 'cover' }}
        ></div>
    </div>
};

export default MobileVersion;
