import { Get, Post, Put, Delete, tokenless } from "../../utils/base-api-request";

const getRequestsUrl = process.env.REACT_APP_BASE_API + "api/support/request/all";
const getRequestUrl = process.env.REACT_APP_BASE_API + "api/support/request";
const removeRequestUrl = process.env.REACT_APP_BASE_API + "api/support/request";
const addAnonymousRequestUrl = process.env.REACT_APP_BASE_API + "api/support/request/anonymous";
const addRequestUrl = process.env.REACT_APP_BASE_API + "api/support/request";
const updateRequestUrl = process.env.REACT_APP_BASE_API + "api/support/request";
const askOctobotUrl = process.env.REACT_APP_BASE_API + "api/support/octobot/request";

export const sortData = (array) => {
  return array.sort((a, b) => {
    if (a.id < b.id) return 1;
    if (a.id > b.id) return -1;

    return 0;
  });
};

export const getAll = async () => {
  const response = await Get(getRequestsUrl);
  return sortData(response.map((r) => requestDtoToRequestView(r)));
};

export const get = async (id) => {
  const response = await Get(getRequestUrl + "/" + id);
  return requestDtoToRequestView(response);
};

export const addAnonymousRequest = async (request, token) => {
  const response = await Post(
    addAnonymousRequestUrl, requestViewToRequestDto({ ...request, token }), { [tokenless]: true, "X-API-KEY": process.env.REACT_APP_API_KEY }
  );
  return requestDtoToRequestView(response);
};

export const add = async (request) => {
  const response = await Post(addRequestUrl, requestViewToRequestDto(request));
  return requestDtoToRequestView(response);
};

export const askOctobot = async (userText) => {
  const response = await Post(askOctobotUrl, userText)
  return response;
};

export const update = async (request) => {
  const response = await Put(updateRequestUrl, requestViewToRequestDto(request));
  return requestDtoToRequestView(response);
};

export const remove = async (id) => {
  return await Delete(removeRequestUrl + "/" + id);
};

const requestDtoToRequestView = (item) => {
  return {
    ...item,
    // creationDate: item.creationDate ? getTime(item.creationDate) : 0,
    // lastModificationDate: item.lastModificationDate ? getTime(item.lastModificationDate) : 0,
  };
};

const requestViewToRequestDto = (item) => {
  return {
    ...item,

  };
};
