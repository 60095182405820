import React from "react";
import { Form } from "react-bootstrap";

const Label = (props) => {
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      {props.title && (
        <Form.Label style={{ fontSize: "var(--theme-font-size)" }}>
          {props.title}
        </Form.Label>
      )}
    </Form.Group>
  );
};

export default Label;
