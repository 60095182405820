import React, { useEffect, useState } from 'react';
import chroma from 'chroma-js';
import { Form } from "react-bootstrap";
import i18next from "i18next";
import Select from 'react-select';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
          ? data.color
          : isFocused
            ? color.alpha(0.1).css()
            : undefined,
      color: isDisabled
        ? '#ccc'
        : isSelected
          ? chroma.contrast(color, 'white') > 2
            ? 'white'
            : 'black'
          : data.color,
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: !isDisabled
          ? isSelected
            ? data.color
            : color.alpha(0.3).css()
          : undefined,
      },
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  multiValueLabel: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: data.color,
      color: chroma.contrast(color, 'white') > 2
        ? 'white'
        : 'black',
    }
  },
  multiValueRemove: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      color: chroma.contrast(color, 'white') > 2
        ? 'white'
        : 'black',
      backgroundColor: data.color,
      ':hover': {
        backgroundColor: color.alpha(0.1).css(),
      },
    }
  }
}

export default (props) => {
  const {
    field,
    title,
    isRequired,
    isReadOnly,
    value,
    options,
    handleChange,
    placeholder,
    dirty,
  } = props;

  const [isDirty, setIsDirty] = useState(false);

  useEffect(
    () => setIsDirty(dirty),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty]
  );

  if (!isReadOnly)
    return <Form.Group key={"form" + field} controlId={field}>
      {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}

      <Select
        closeMenuOnSelect={false}
        isMulti
        options={options}
        onChange={(selected) => {
          setIsDirty(true);
          return handleChange(
            field,
            selected.map((opt) => opt.value)
          );
        }}
        allowSelectAll={true}
        value={
          value
            ? value.map((v) => options.find((o) => o.value === v))
            : []
        }
        required={isRequired}
        styles={colourStyles}
        menuPortalTarget={document.body}
        menuPosition='fixed'
        placeholder={placeholder ?? i18next.t("Select") + "..."}
      />
      {isDirty && isRequired && (!value || value.length === 0) && (
        <text className="text-error">
          {i18next.t("required field")}
        </text>
      )}
    </Form.Group>
  else
    return (
      <Form.Group key={"form" + field} controlId={field}>
        {title && <Form.Label>{title}</Form.Label>}
        <Form.Control
          key={field}
          type="text"
          required={isRequired}
          value={value ? value : ""}
          disabled={true}
          isInvalid={isDirty && isRequired && !value}
        />
      </Form.Group>
    );
}