import React, { useState } from "react";
import { ContentCopyIcon } from "../../Icons/icons";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@mui/lab/Alert";
import i18next from "i18next";

const Clipboard = (props) => {
  const { value, style } = props;
  const [isCopied, setIsCopied] = useState(false)

  return props.value ?
    (<div style={{ display: "flex", cursor: "pointer" }} >
      <ContentCopyIcon onClick={() => { navigator.clipboard.writeText(value); setIsCopied(true) }} style={{
        ...{
          marginLeft: "6px",
          marginTop: "10px",
          fontSize: "20px"
        }, ...style
      }} />
      {isCopied && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={isCopied}
          onClose={() => setIsCopied(false)}
          autoHideDuration={700}
        >
          <MuiAlert elevation={6} variant="filled" open={isCopied} onClose={() => setIsCopied(false)} severity={"success"} sx={{ width: '100%' }}>
            {i18next.t("Copied")}
          </MuiAlert>
        </Snackbar>
      )}
    </div>
    ) : <></>;
};

export default Clipboard;
