import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForTransactions = (can, roles) => {
    if ([permissions.ALL].some((role) => roles.includes(role))) {
        can(permissions.MANAGE, subjects.TRANSACTIONS);
    } else {
        roles.forEach((perm) => {
            can(perm, subjects.TRANSACTIONS);
        });
    }
};

export const canAddTransaction = () =>
    store
        .getState()
        .authentication.user.ability.can(
            permissions.TRANSACTION_ADD,
            subjects.TRANSACTIONS
        );

export const canUpdateTransaction = () =>
    store
        .getState()
        .authentication.user.ability.can(
            permissions.TRANSACTION_UPDATE,
            subjects.TRANSACTIONS
        );

export const canDeleteTransaction = () =>
    store
        .getState()
        .authentication.user.ability.can(
            permissions.TRANSACTION_DELETE,
            subjects.TRANSACTIONS
        );
