/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import PropTypes from "prop-types";
import i18next from "i18next";
import CreatableSelect from 'react-select/creatable';
import { Form } from "react-bootstrap";
import { useEffect } from "react";
import Clipboard from "../others/Clipboard";
import { validateArray } from "../../../../utils/input";

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label
});

const MultiSelectText = (props) => {
  const {
    field,
    title,
    isRequired,
    isReadOnly,
    value,
    handleChange,
    options = [],
    dirty,
    placeholder,
    enableCopy,
    textValidatorFunc,
    validationErrorMessage
  } = props;

  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState([]);
  useEffect(
    () => setIsDirty(dirty),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirty]
  );
  const [editedValue, setValue] = useState([])
  const [submitChanges, setSubmitChanges] = useState(false);

  useEffect(() => {
    if (textValidatorFunc) {
      setErrors(validateArray(value, textValidatorFunc))
    }
    else setErrors([])
    setValue(value ? value.map(v => createOption(v)) : [])
  }, [value])

  useEffect(() => {
    if (submitChanges) {
      if (textValidatorFunc) {
        setErrors(validateArray(editedValue.map(v => v.value), textValidatorFunc))
      }
      else setErrors([])
      handleChange(field, editedValue.map(v => v.value));
      setSubmitChanges(false);
    }
  }, [editedValue, submitChanges])

  if (!isReadOnly)
    return (
      <Form.Group key={"form" + field} controlId={field}>
        {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}
        <div style={{
          display: "flex",
        }}>
          <CreatableSelect
            components={components}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor:
                  (isDirty && isRequired && (!editedValue || editedValue.length === 0)) || errors.length > 0
                    ? "#dc3545"
                    : baseStyles.borderColor,
              }),
              multiValue: (styles, { data }) => ({
                ...styles,
                color: errors.includes(data.value) ? "red" : styles.color,
              }),
              multiValueLabel: (styles, { data }) => ({
                ...styles,
                color: errors.includes(data.value) ? "red" : styles.color,
              })
            }}
            isClearable
            isMulti
            options={options}
            onChange={(newValue) => { setIsDirty(true); setValue(newValue); setSubmitChanges(true); }}

            placeholder={placeholder}
            value={editedValue}
            required={isRequired}
          />
          {enableCopy && editedValue.length > 0 && <Clipboard value={value ? value.join('; ') : ""} />}
        </div>
        {isDirty && isRequired && (!editedValue || editedValue.length === 0) && (
          <text className="text-error">
            {i18next.t("required field")}
          </text>
        )}
        {errors.length > 0 && (
          <text className="text-error">
            {validationErrorMessage}
          </text>
        )}
      </Form.Group>
    );
  else
    return (
      <Form.Group key={"form" + field} controlId={field}>
        {title && <Form.Label>{title}</Form.Label>}
        <Form.Control
          key={field}
          type="text"
          required={isRequired}
          value={editedValue ? editedValue : ""}
          disabled={true}
          isInvalid={isDirty && isRequired && !editedValue}
        />
      </Form.Group>
    );
};

MultiSelectText.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default MultiSelectText;
