import React from "react";
import Button from "react-bootstrap/Button";

const Delete = (props) => {
  return (
    <Button
      style={{ fontSize: "var(--theme-font-size)" }}
      key={"form" + props.field}
      onClick={() => props.handleChange(props.field)}
      variant="danger"
      className="float-right"
      disabled={props.isReadOnly}
    >
      {props.title}
    </Button>
  );
};

export default Delete;
