import i18next from "i18next";

export const translateValue = (value) => {
    if (Array.isArray(value)) {
        return value.map((item) => translateValue(item));
    } else if (typeof value === "object" && value !== null) {
        if ("label" in value) {
            return { ...value, label: i18next.t(value.label) };
        }
        return Object.keys(value).reduce((acc, key) => {
            acc[key] = translateValue(value[key]);
            return acc;
        }, {});
    } else {
        return i18next.t(value);
    }
};
const propertiesToTranslate = ["title", "placeholder", "addnewTitle", "label", "validationErrorMessage"];
const translateProxyHandler = {
    get(target, prop) {
        const value = target[prop];
        // Check if the property ends with "Key" (e.g., titleKey, placeholderKey)
        if (propertiesToTranslate.includes(prop)) {
            return i18next.t(value);
        }
        if (["lookup", "options"].includes(prop) && value) {
            return translateValue(value);
        }
        if (prop === "filters" && value) {
            return Object.keys(value).reduce((acc, key) => {
                acc[key] = {
                    ...value[key],
                    display: i18next.t(value[key].display),
                    fields: value[key].fields.map((field) => ({
                        ...field,
                        display: i18next.t(field.display),
                        options: translateValue(field.options)
                    })),
                };
                return acc;
            }, {});
        }
        return value;
    },
};

export const withTranslations = (constants) => {
    return new Proxy(constants, translateProxyHandler);
};
