import React from "react";
import Button from "react-bootstrap/Button";

const Cancel = (props) => {
  return (
    <Button
      style={{
        fontSize: "var(--theme-font-size)",
        fontWeight: 'bold',
        color: 'grey'
      }}
      key={"form" + props.field}
      variant="link"
      className="float-right"
      right="true"
      onClick={() => props.handleCancel()}
      disabled={props.isReadOnly}
    >
      {props.title}
    </Button>
  );
};

export default Cancel;
