import * as subjects from "../../../constants/subjects.js";
import * as permissions from "../constants/permissions";
import { store } from "../../../store/index.js";

export const defineRulesForBilling = (can, roles) => {
  if ([permissions.ALL].some((role) => roles.includes(role))) {
    can(permissions.MANAGE, subjects.BILLING);
  } else {
    roles.forEach((perm) => {
      can(perm, subjects.BILLING);
    });
  }
};

export const canAddBill = () =>
  store
    .getState()
    .authentication.user.ability.can(permissions.BILLING_ADD, subjects.BILLING);

export const canUpdateBill = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.BILLING_UPDATE,
      subjects.BILLING
    );

export const canDeleteBill = () =>
  store
    .getState()
    .authentication.user.ability.can(
      permissions.BILLING_DELETE,
      subjects.BILLING
    );
