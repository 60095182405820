import i18next from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { SketchPicker } from 'react-color';

const ColorSelector = (props) => {
    const {
        field,
        title,
        isRequired,
        isReadOnly,
        value,
        defaultValue = "#000000",
        handleChange,
        style
    } = props;

    const [color, setColor] = useState(value || defaultValue);
    const [showPalette, setShowPalette] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        // Add event listener to detect clicks outside the table
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            // Cleanup the event listener on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowPalette(false)
        }
    };

    const handleColorChange = (newColor) => {
        setColor(newColor.hex);
        handleChange(field, newColor.hex);
    };

    const togglePalette = () => {
        if (!isReadOnly)
            setShowPalette(!showPalette);
    };

    return (
        // <Form.Group key={"form" + field} controlid={"date-picker-inline" + field}>
        <div key={"form" + field} controlid={"date-picker-inline" + field}>
            {title && <Form.Label>{isRequired ? title + "*" : title}</Form.Label>}
            <div
                style={{
                    ...{
                        width: '40px',
                        height: '40px',
                        backgroundColor: value,
                        cursor: 'pointer',
                        borderRadius: "12px",
                        borderStyle: "solid",
                        borderWidth: "thin"
                    }, ...style
                }}
                onClick={togglePalette}
            />
            {showPalette && (
                <div
                    ref={ref}
                    style={{
                        position: 'fixed',
                        zIndex: '9999',
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '5px' }}>
                        <span>{i18next.t('Color Picker')}</span>
                        <span onClick={togglePalette} style={{ cursor: 'pointer', fontWeight: "bold" }} >X</span>
                    </div>
                    <SketchPicker
                        color={color}
                        onChange={handleColorChange}
                        disableAlpha={true}
                        presetColors={[]}
                        width="200px"
                        height="150px"
                    />
                </div>
            )}
            {/* </Form.Group>) */}
        </div >)

};

export default ColorSelector;