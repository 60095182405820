import React from "react";
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import { Spinner } from "reactstrap";
import { useSelector } from "react-redux";

const Loader = (props) => {
  const isLoading = useSelector((state) => state.common.isLoading);
  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner={<Spinner color="primary" />}>
      {props.children}
    </LoadingOverlay>
  );
};

export default Loader;
