import React from "react";
import { Form } from "react-bootstrap";

const Switch = (props) => {
  return (
    <Form.Group key={"form" + props.field} controlId={props.field}>
      <Form.Check
        style={
          props.isInline
            ? { fontSize: "var(--theme-font-size)", paddingTop: "35px" }
            : { fontSize: "var(--theme-font-size)" }
        }
        key={props.field}
        type="switch"
        label={props.isRequired ? props.title + "*" : props.title}
        checked={props.value ? props.value : false}
        onChange={(e) => props.handleChange(props.field, e.target.checked)}
        disabled={props.isReadOnly}
      />
    </Form.Group>
  );
};

export default Switch;
