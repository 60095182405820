import { FETCH_DATA } from "../../constants/actions-types";
import { mergeMap } from "rxjs/operators";
import { of, merge } from "rxjs";
import { ofType } from "redux-observable";
import { fetchProfessionalInfo } from "../../actions/profile/professionalInfo";
import { fetchProjects } from "../../actions/reporting/project";
import { getUserPicture } from "../../actions/authentication";

const fetchData = (action$) =>
  action$.pipe(
    ofType(FETCH_DATA),
    mergeMap(() =>
      merge(
        of(getUserPicture()),
        of(fetchProfessionalInfo()),
        of(fetchProjects())
      )
    )
  );

export default fetchData;
