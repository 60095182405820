import React, { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import i18next from "i18next";
import Clipboard from "../others/Clipboard";
import { InfoIcon } from "../../Icons/icons";
import IBAN from 'iban';

export const isValidIban = (value) => {
  return value ? IBAN.isValid(value) : true
};

export const ibanInput = (field, isRequired, isReadOnly, placeholder, value, handleChange, style, label, isDirty, setIsDirty, enableCopy) => {
  const onChange = (field, value, target) => {
    handleChange(field, value)
    const caret = target.selectionStart
    const element = target
    window.requestAnimationFrame(() => {
      element.selectionStart = caret
      element.selectionEnd = caret
    })
  }

  return label ?
    <>
      <InputGroup className="mb-2" style={{ background: "#ffffff" }}>
        <div style={{
          display: "flex",
          width: "100%"
        }}>
          <Form.Control
            style={{ ...{ fontSize: "var(--theme-font-size)" }, style }}
            key={field}
            type="text"
            required={isRequired}
            value={value ? value : ""}
            onChange={(e) => {
              if (setIsDirty) setIsDirty(true);
              onChange(field, e.target.value, e.target);
            }}
            disabled={isReadOnly}
            placeholder={placeholder}
            isInvalid={
              (isDirty) &&
              ((isRequired && !value) || !isValidIban(value))
            }
          />
          <InputGroup.Prepend style={{
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
            background: "gainsboro"
          }} >
            <InputGroup.Text style={{
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              background: "gainsboro"
            }}>{label}</InputGroup.Text>
          </InputGroup.Prepend>
          {enableCopy && <Clipboard value={value} />}
        </div>
        <Form.Control.Feedback type="invalid">
          {isDirty && isRequired && !value && i18next.t("required field")}
          {isDirty && value && !isValidIban(value) && i18next.t("required field")}
        </Form.Control.Feedback>
      </InputGroup>
    </> : <>
      <div style={{
        display: "flex",
        width: "100%"
      }}>
        <Form.Control
          style={{ ...{ fontSize: "var(--theme-font-size)" }, style }}
          key={field}
          type="text"
          required={isRequired}
          value={value ? value : ""}
          onChange={(e) => {
            if (setIsDirty) setIsDirty(true);
            onChange(field, e.target.value, e.target);
          }}
          disabled={isReadOnly}
          placeholder={placeholder}
          isInvalid={
            (isDirty) &&
            ((isRequired && !value) || !isValidIban(value))
          }
        />
        {enableCopy && <Clipboard value={value} />}
      </div >
      <Form.Control.Feedback type="invalid" style={isDirty && ((isRequired && !value) || (value && !isValidIban(value))) ? { display: "flex" } : {}}>
        {isDirty && isRequired && !value && i18next.t("required field")}
        {isDirty && value && !isValidIban(value) && i18next.t("enter a valid IBAN")}
      </Form.Control.Feedback>
    </>
}

const Iban = (props) => {
  const {
    field,
    title,
    isRequired,
    value,
    isReadOnly,
    placeholder,
    handleChange,
    style,
    dirty,
    label,
    enableCopy,
    info
  } = props;
  const [isDirty, setIsDirty] = useState(false);

  return (
    <Form.Group key={"form" + field} controlId={field}>
      {props.title && (
        <div>
          <Form.Label>
            {isRequired ? title + "*" : title}
          </Form.Label>
          {info && <span style={{
            position: "relative",
            top: "-1px"
          }}>
            <InfoIcon tooltip={info} /></span>}
        </div>
      )}
      {ibanInput(field, isRequired, isReadOnly, placeholder, value, handleChange, style, label, dirty ?? isDirty, setIsDirty, enableCopy)}
    </Form.Group>
  );
};

export default Iban;
